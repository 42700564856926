import styled from "styled-components";

import { StyledParagraph } from "../../components/styles/styledDocumentElements";
import { StyledTitle } from "../../components/styles/styledTitle";
import { StyledContentWrapper } from "../../components/styles/styledContainer";

export const StyledNotFoundContentWrapper = styled(StyledContentWrapper)`
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: unset;
`;

export const StyledNotFoundWrapper = styled.div`
  padding: 104px 15px 232px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.26%, #9CB8BB 97.63%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  @media screen and (max-width: 992px) {
    padding: 80px 15px 150px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 15px;
  }
  
  & .not-found-info {
    margin-bottom: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
      a.not-found__go-homepage {
        padding: 16px 30px;
        font-size: 18px;
        border-radius: 10px;
      }
    }
    @media screen and (max-width: 455px) {
      a.not-found__go-homepage {
        padding: 10px 20px;
        font-size: 14px;
        border-radius: 5px;
      }
    }
  }
  
  ${StyledTitle} {
    margin-bottom: 20px;
    font-size: 80px;
    font-weight: 750;
    line-height: 130%;

    @media screen and (max-width: 768px) {
      font-size: 40px !important;
      text-align: center;
    }
  }

  ${StyledParagraph} {
    max-width: 430px;
    width: 100%;
    margin: 0 auto 36px;
    font-size: 18px;
    font-weight: 400;
    line-height: 170%;
    text-align: center;
    
    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  
  @media screen and (max-width: 992px) {
    ${StyledTitle} {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    ${StyledParagraph} {
      margin: 0 auto 28px;
      line-height: 130%;
    }
  }
`;

export const StyledImageWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;