import React, { useEffect, useState } from "react";
import Dialog from "rc-dialog";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StyledButton } from "../../styles/styledButton";
import eventBus from "../../../utils/eventBus";

const AutoLogoutModal = ({ authenticated, setAuthenticated }) => {
  const history = useHistory();
  const { t } = useTranslation("autoLogout", { useSuspense: false });

  const [visible, setVisible] = useState(false);

  const handleOnIdle = () => {
    eventBus.dispatch("autoLogoutEvent");
  };

  const { getLastActiveTime } = useIdleTimer({
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"
    ],
    crossTab: true,
    syncTimers: 200,
    timeout: 2000 * 60 * 30,
    onIdle: handleOnIdle
  });

  const handleSubmit = () => {
    setVisible(false);
    setAuthenticated(false);
  };

  useEffect(() => {
    eventBus.on("autoLogoutEvent", () => {
      if (localStorage.getItem("token")) {
        setVisible(true);
        localStorage.removeItem("token");

        history.push("/");
      }
    });
    eventBus.remove("autoLogoutEvent");
  }, [authenticated]);

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title={t("attention")}
      forceRender={false}
      className="default-modal default-modal__autologout"
      maskClosable={false}
      closable={false}
    >
      <div className="default-modal__content">
        <p>
          {t("text1")}
        </p>
        <p>
          {t("text2")}
        </p>
      </div>
      <div className="default-modal__footer">
        <StyledButton
          color="main"
          border
          onClick={handleSubmit}
        >
          {t("buttonOk")}
        </StyledButton>
      </div>
    </Dialog>
  );
};

export default AutoLogoutModal;