import React, { lazy } from "react";
import routes from "./routes";
import { supportedLanguages } from "../utils/consts";

const AccountVerificationWrapper = lazy(() => import("../components/accountVerification/AccountVerificationWrapper"));
const CurrenciesPage = lazy(() => import("../pages/currencies/CurrenciesPage"));
const PaymentSettingsPage = lazy(() => import("../pages/paymentSettings/PaymentSettingsPage"));
const ClientsPage = lazy(() => import("../pages/clients/ClientsPage"));
const FeedbacksPage = lazy(() => import("../pages/feedbacks/FeedbacksPage"));
const FeedbackDetailsPage = lazy(() => import("../pages/feedbacks/FeedbackDetailsPage"));
const ClientsDetailsPage = lazy(() => import("../pages/clients/ClientsDetailsPage"));
const ProjectsPage = lazy(() => import("../pages/projects/ProjectsPage"));
const ProjectsDetailsPage = lazy(() => import("../pages/projects/ProjectsDetailsPage"));
const LogsPage = lazy(() => import("../pages/logs/LogsPage"));
const TransactionsDetailsPage = lazy(() => import("../pages/projects/TransactionsDetailsPage"));
const CashFlowPage = lazy(() => import("../pages/cashFlow/CashFlowPage"));
const AccountSettings = lazy(() => import("../pages/account/AccountSettingsPage"));
const VerifyDocumentPage = lazy(() => import("../pages/account/VerifyDocumentPage"));
const AdminNewsPage = lazy(() => import("../pages/news/adminAndSeo/NewsPage"));
const AdminCreatePost = lazy(() => import("../pages/news/adminAndSeo/CreateNews"));
const AdminEditPost = lazy(() => import("../pages/news/adminAndSeo/EditNewsPage"));
const BatchesPage = lazy(() => import("../pages/batches/BatchesPage"));
const BatchesDetailsPage = lazy(() => import("../pages/batches/BatchesDetailsPage"));
const CashFlowPageDetail = lazy(() => import("../pages/cashFlow/CashFlowPageDetail"));
const StaticPage = lazy(() => import("../pages/staticPages/StaticPage"));
const CreateStaticPage = lazy(() => import("../pages/staticPages/CreateStaticPage"));
const EditStaticPage = lazy(() => import("../pages/staticPages/EditStaticPage"));
const InvoicePage = lazy(() => import("../pages/invoice/InvoicePage"));
const TrafficLinksPage = lazy(() => import("../pages/trafficLinks/TrafficLinksPage"));
const TrafficLinksDetailsPage = lazy(() => import("../pages/trafficLinks/TrafficLinksDetailsPage"));
const TrafficLinksClientsDetailsPage = lazy(() => import("../pages/trafficLinks/TrafficLinksClientsDetailsPage"));
const TrafficLinksTransactionsDetailsPage = lazy(() => import("../pages/trafficLinks/TrafficLinksTransactionsDetailsPage"));
const LoyaltyProgramPage = React.lazy(() => import("../pages/loyaltyProgram/LoyaltyProgramPage"));
const DashboardPage = React.lazy(() => import("../pages/dashboard/DashboardPage"));
const PayByLinkDetailsPage = React.lazy(() => import("../pages/invoice/pay-by-link/PayByLinkDetailsPage"));
const StandardInvoiceDetailsPage = React.lazy(() => import("../pages/invoice/standard/StandardInvoiceDetailsPage"));
const ClientLoyaltyProgramReferralsPartnersProfitDetails = React.lazy(() => import("../pages/loyaltyProgram/LoyaltyProgramReferralsPartnersProfitDetails"));
const AdminLoyaltyProgramReferralsPartnersProfitDetails = React.lazy(() => import("../pages/clients/LoyaltyProgramReferralsPartnersProfitDetails"));
const IntegrationsPage = React.lazy(() => import("../pages/integrations/IntegrationsPage"));
const PayButtonDetailsPage = React.lazy(() => import("../pages/integrations/pay-buttons/PayButtonDetailsPage"));
const PosLinkDetailsPage = React.lazy(() => import("../pages/invoice/pos-terminal-link/PosLinkDetailsPage"));
const ExchangesDetailsPage = React.lazy(() => import("../pages/exchanges/ExchangesDetailsPage"));
const FiatPayoutsDetailsPage = React.lazy(() => import("../pages/fiatPayout/FiatPayoutsDetailsPage"));
const RequisitesVerificationPage = React.lazy(() => import("../pages/requisitesVerification/RequisitesVerificationPage"));
const RequisitesVerificationDetailsPage = React.lazy(() => import("../pages/requisitesVerification/RequisitesVerificationDetailsPage"));
const NotificationsHistoryPage = React.lazy(() => import("../pages/notificationsHistory/NotificationsHistoryPage"));
const FeeGroupPage = lazy(() => import("../pages/feeGroups/FeeGroupsPage"));
const TransactionProfitStablingPage = lazy(() => import("../pages/profitStabling/TransactionProfitStablingDetailsPage"));
const ExchangeProfitDetailsPage = lazy(() => import("../pages/profitStabling/ExchangeProfitStablingDetailsPage"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`
});

const userRoutes = [
  {
    title: "Clients",
    path: `/:locale(${locale})?/panel/clients`,
    component: ClientsPage
  },
  {
    title: "Feedbacks list",
    path: `/:locale(${locale})?/panel/tickets`,
    exact: true,
    component: FeedbacksPage
  },
  {
    title: "Feedbacks Details",
    path: `/:locale(${locale})?/panel/tickets/details/:id`,
    exact: true,
    component: FeedbackDetailsPage
  },
  {
    title: "Clients detail",
    path: `/:locale(${locale})?/panel/client-details/:id`,
    exact: true,
    component: ClientsDetailsPage
  },
  {
    title: "Projects",
    path: `/:locale(${locale})?/panel/projects`,
    component: ProjectsPage
  },
  {
    title: "Projects detail",
    path: `/:locale(${locale})?/panel/projects-details/:id`,
    exact: true,
    component: ProjectsDetailsPage
  },
  {
    title: "Logs",
    path: `/:locale(${locale})?/panel/logs`,
    exact: true,
    component: LogsPage
  },
  {
    title: "Projects detail",
    path: `/:locale(${locale})?/panel/transaction-details/:id`,
    exact: true,
    component: TransactionsDetailsPage
  },
  {
    title: "Exchange detail",
    path: `/:locale(${locale})?/panel/exchange-details/:id`,
    exact: true,
    component: ExchangesDetailsPage
  },
  {
    title: "Fiat payout details",
    path: `/:locale(${locale})?/panel/fiat-payouts-details/:id`,
    exact: true,
    component: FiatPayoutsDetailsPage
  },
  {
    title: "Cash Flow",
    path: `/:locale(${locale})?/panel/cash-flow`,
    exact: true,
    component: CashFlowPage
  },
  {
    title: "Cash Flow Projects",
    path: `/:locale(${locale})?/panel/cash-flow/projects/:currency`,
    component: CashFlowPageDetail
  },
  {
    title: "Account page",
    path: `/:locale(${locale})?/panel/settings`,
    component: AccountSettings
  },
  {
    title: "Account Verification",
    path: `/:locale(${locale})?/panel/documents-verification`,
    exact: false,
    component: AccountVerificationWrapper
  },
  {
    title: "Verification page",
    path: `/:locale(${locale})?/panel/document/verification`,
    component: VerifyDocumentPage
  },
  {
    title: "Payment settings page",
    path: `/:locale(${locale})?/panel/payment-setting`,
    component: PaymentSettingsPage
  },
  {
    title: "Currencies page",
    path: `/:locale(${locale})?/panel/currencies`,
    component: CurrenciesPage
  },
  {
    title: "News Admin",
    path: `/:locale(${locale})?/panel/blog`,
    exact: true,
    component: AdminNewsPage
  },
  {
    title: "NewsCreatePost",
    path: `/:locale(${locale})?/panel/blog/create`,
    component: AdminCreatePost
  },
  {
    title: "AdminEditPost",
    path: `/:locale(${locale})?/panel/blog/edit/:id`,
    component: AdminEditPost
  },
  {
    title: "Batches",
    path: `/:locale(${locale})?/panel/batches`,
    component: BatchesPage
  },
  {
    title: "Batches details",
    path: `/:locale(${locale})?/panel/batches-details/:id`,
    exact: true,
    component: BatchesDetailsPage
  },
  {
    title: "Static pages configure",
    path: `/:locale(${locale})?/panel/static-pages`,
    exact: true,
    component: StaticPage
  },
  {
    title: "Create static pages",
    path: `/:locale(${locale})?/panel/static-pages/create`,
    component: CreateStaticPage
  },
  {
    title: "Edit static pages",
    path: `/:locale(${locale})?/panel/static-pages/edit/:id`,
    component: EditStaticPage
  },
  {
    title: "Invoice",
    path: `/:locale(${locale})?/panel/invoices`,
    component: InvoicePage
  },
  {
    title: "Invoice",
    path: `/:locale(${locale})?/panel/pay-by-link/:id`,
    component: PayByLinkDetailsPage
  },
  {
    title: "Invoice",
    path: `/:locale(${locale})?/panel/standard-invoice/:id`,
    component: StandardInvoiceDetailsPage
  },
  {
    title: "Traffic Links",
    path: `/:locale(${locale})?/panel/traffic-links`,
    exact: true,
    component: TrafficLinksPage
  },
  {
    title: "Traffic Link Details",
    path: `/:locale(${locale})?/panel/traffic-details/:id`,
    component: TrafficLinksDetailsPage
  },
  {
    title: "Traffic Link Users Details",
    path: `/:locale(${locale})?/panel/traffic-users-details/:id`,
    component: TrafficLinksClientsDetailsPage
  },
  {
    title: "Traffic Link Transactions Details",
    path: `/:locale(${locale})?/panel/traffic-transactions-details/:id`,
    component: TrafficLinksTransactionsDetailsPage
  },
  {
    title: "Loyalty Program",
    path: `/:locale(${locale})?/panel/loyalty-program`,
    exact: true,
    component: LoyaltyProgramPage
  },
  {
    title: "Dashboard",
    path: `/:locale(${locale})?/panel/dashboard`,
    exact: true,
    component: DashboardPage
  },
  {
    title: "Referrals Partners Profit Details",
    path: `/:locale(${locale})?/panel/referrals-partners-profit-details/:id`,
    component: ClientLoyaltyProgramReferralsPartnersProfitDetails
  },
  {
    title: "Referrals Partners Profit Details",
    path: `/:locale(${locale})?/panel/client-referrals-partners-profit-details/:ancestorId/:clientId`,
    component: AdminLoyaltyProgramReferralsPartnersProfitDetails
  },
  {
    title: "Integrations",
    path: `/:locale(${locale})?/panel/integrations`,
    component: IntegrationsPage
  },
  {
    title: "Pay Buttons",
    path: `/:locale(${locale})?/panel/pay-button/:id`,
    component: PayButtonDetailsPage
  },
  {
    title: "Pay Buttons",
    path: `/:locale(${locale})?/panel/pos-terminal-link/:id`,
    component: PosLinkDetailsPage
  },
  {
    title: "Requisites verification",
    path: `/:locale(${locale})?/panel/requisites-verification`,
    exact: true,
    component: RequisitesVerificationPage
  },
  {
    title: "Requisites verification details",
    path: `/:locale(${locale})?/panel/requisites-verification-details/:id`,
    exact: true,
    component: RequisitesVerificationDetailsPage
  },
  // {
  //   title: "Reports",
  //   path: `/:locale(${locale})?/panel/reports`,
  //   exact: true,
  //   component: ReportsPage
  // },
  {
    title: "Notifications",
    path: `/:locale(${locale})?/panel/notifications-history`,
    exact: true,
    component: NotificationsHistoryPage
  },
  {
    title: "Fee group",
    path: `/:locale(${locale})?/panel/fee-group`,
    exact: true,
    component: FeeGroupPage
  },
  {
    title: "Transaction profit stabling details",
    path: `/:locale(${locale})?/panel/transaction-profit-stabling/:id`,
    exact: true,
    component: TransactionProfitStablingPage
  },
  {
    title: "Exchange profit stabling details",
    path: `/:locale(${locale})?/panel/exchange-profit-stabling/:id`,
    exact: true,
    component: ExchangeProfitDetailsPage
  }
];
const userRoutesConcat = userRoutes.concat(routes);

export default userRoutesConcat;