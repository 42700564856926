import React from "react";
import { css } from "@emotion/react";
import { PuffLoader, MoonLoader } from "react-spinners";

const Spinner = ({ size, color, display, margin, type }) => {

  const override = css`
    display: ${display};
    margin: ${margin || "15px auto"};
  `;

  switch (type) {
    case 'puffLoader' :
      return <PuffLoader css={override} size={size} color={color} loading={true} />;
    default :
      return <MoonLoader css={override} size={size} color={color} loading={true}/>;
  }
};

export default Spinner;