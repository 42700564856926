import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import backToTopIcon from "../../../assets/images/backToTopIcon.png";

import { StyledBackToTopButton } from "./styledBackToTop";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const actionWithScroll = () => {
      toggleVisibility();
      updateProgress();
    };
    document.addEventListener("scroll", actionWithScroll);
    return () => {
      document.removeEventListener("scroll", actionWithScroll);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const updateProgress = () => {
    const scrollPos = document.documentElement.scrollTop;
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const progress = (scrollPos / totalHeight) * 100 + 0.5;

    setProgress(progress);
  };

  const scrollToTop = () => {
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    let duration = 500;

    if (totalHeight > 1500) {
      duration = 1000;
    } else if (totalHeight > 3000) {
      duration = 1500;
    } else if (totalHeight > 5000) {
      duration = 2000;
    } else if (totalHeight > 7500) {
      duration = 2500;
    }

    const start = window.pageYOffset;
    const startTime = performance.now();
    const animateScroll = (timestamp) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      window.scrollTo(0, start * (1 - progress));
      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };
    requestAnimationFrame(animateScroll);
  };

  return (
    <StyledBackToTopButton
      onClick={() => setTimeout(() => scrollToTop(), 350) }
      isVisible={isVisible}
      aria-label="Back to top"
    >
      <svg
        viewBox="0 0 64 64"
        className="progress"
      >
        <circle
          className="background"
          r="25%"
          cx="50%"
          cy="50%"
        ></circle>
        <circle
          className="inside-line"
          r="23%"
          cx="50%"
          cy="50%"
        ></circle>
        <circle
          className="chart"
          r="25%"
          cx="50%"
          cy="50%"
          strokeDasharray={`${progress} 100`}
        ></circle>

      </svg>
      <LazyLoadImage
        width={49}
        height={72}
        src={backToTopIcon}
        alt="back to top image"
      />
    </StyledBackToTopButton>
  );
};

export default BackToTop;