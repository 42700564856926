import { closableNotification } from "../components/elements/notification/ClosableNotification";

const parserArrayFromObject = (response) => {
  return Object.entries(response).map(([key, value]) => {
    return {
      [key]: value
    };
  });
};

const parseAxiosError = (response, setError = null) => {

  if (!response) {
    return;
  }

  if (Array.isArray(response[0])) {

    const parseError = response.flat().toString();
    return closableNotification(parseError, "error");
  }

  if (!Array.isArray(response[0]) && setError) {
    setError({});

    const customResponse = !Array.isArray(response) ? parserArrayFromObject(response) : response;

    let errorsCustom = {};

    customResponse.forEach(elementError => {

      const [key, value] = Object.entries(elementError).flat();

      if (key.includes('alerted') || key === "") {
        return closableNotification(value, "error");
      }

      errorsCustom[key] = value;
    });

    return setError(prev => ({ ...prev, ...errorsCustom }));
  }

  if (!setError && response[0]) {
    const purseClosableErrors = Object.values(response[0]).toString();
    return closableNotification(purseClosableErrors, "error");
  }

};

const parseIRI = (IRI) => {
  const regex = /\d+/i;
  return +IRI.match(regex)[0];
};

const parseUuidIRI = (IRI) => {
  return IRI.split("/")[3];
};

const hideCreditCardSigns = (cardNumber) => {
  let cardNumberFirst = cardNumber.slice(0, 6);
  let cardNumberLast = cardNumber.substr(cardNumber.length - 4);
  return cardNumberFirst + "******" + cardNumberLast;
};

const parseAxiosLangError = (collection) => {
  let errors = {};
  let tempErrors = JSON.parse(collection);

  if (collection) {
    Object.keys(tempErrors).map(item => {
      errors[item] = tempErrors[item];
    });
  }

  return errors;
};

export {
  parseAxiosError,
  parseUuidIRI,
  parseIRI,
  hideCreditCardSigns,
  parseAxiosLangError
};