import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactHtmlParser from "react-html-parser";

import { responseStatus, standardErrorsWay } from "../../../utils/consts";
import eventBusMercure from "../../../utils/eventBusMercure";
import getUserInfo from "../../../utils/getUserInfo";
import { parseAxiosError } from "../../../utils/response";
import { TimestampToDateInUTCFormat } from "../../../utils/timestampToDate";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import {
  StyledAlarmWrapper,
  StyledButtonClose,
  StyledIconNotificationWrapper,
  StyledNotificationItem,
  StyledNotificationsCloseAllWrapper,
  StyledNotificationsContentWrapper,
  StyledNotificationsWrapper,
  StyledNotificationText,
  StyledNumbersOfNotification
} from "./styledNotifications";

const NotificationsContainer = ({ authenticated, notifications, setNotifications }) => {

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(null);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [closeLoading, setCloseLoading] = useState(false);
  const [cookies] = useCookies(["i18next"]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);

  const fetchNotifications = () => {
    axios.get("/api/notifications?isRead=false", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        const visibleNotifications = response.data["hydra:member"].filter(object => object.isHidden === false);

        setNotifications(response.data["hydra:member"]);
        setVisibleNotifications(visibleNotifications);
        setCurrentNotificationIndex(visibleNotifications.length - 1);
      }
    }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    }).finally(() => {
      setLoadingNotifications(false);
      setCloseLoading(false);
    });
  };

  const handleCloseNotification = (notificationId) => {
    setCloseLoading(true);

    const data = { isHidden: true };
    axios.put(`/api/notifications/${notificationId}`, data, userAuthenticationConfig()).then(response => {
      setVisibleNotifications(prev => prev.filter(object => object.id !== notificationId));
      setCurrentNotificationIndex(prev => prev === 0 ? visibleNotifications.length - 2 : prev - 1);
      setCloseLoading(false);
    }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    })
  };

  const handleCloseAllNotifications = () => {
    axios.post("/api/close-all-notifications", { }, userAuthenticationConfig()).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    });
  };

  let params = null;

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    fetchNotifications();

    params = getUserInfo()["userId"];

    eventBusMercure.addTopic(fetchNotifications, "notifications", params);

    return () => {
      eventBusMercure.removeTopic(fetchNotifications, "notifications", params);
    };
  }, [authenticated]);

  const prevNotification = () => {
    setCurrentNotificationIndex(prev => prev === 0 ? visibleNotifications.length - 1 : prev - 1);
  };

  const nextNotification = () => {
    setCurrentNotificationIndex(prev => prev === visibleNotifications.length - 1 ? 0 : prev + 1);
  };

  const closeAllButtonText = (lang) => {
    switch (lang) {
      case "en":
        return "Close all";
      case "ru":
        return "Закрыть все";
      case "ua":
        return "Закрити всі";
    }
  };

  if (loadingNotifications && !currentNotificationIndex) {
    return <></>;
  }

  return (
    <>
      {authenticated && visibleNotifications.length > 0 ? (<StyledNotificationsContentWrapper>
        <StyledIconNotificationWrapper>
          {
            visibleNotifications.length > 1 && <button type="button" onClick={prevNotification} aria-label="Read previous notification">
              <span className="icon-chevron-top" />
            </button>
          }
          <StyledAlarmWrapper>
            <span className="icon-notification" />
            {visibleNotifications.length && <StyledNumbersOfNotification notificationLength={visibleNotifications.length}>
              {visibleNotifications.length}
            </StyledNumbersOfNotification>}
          </StyledAlarmWrapper>
          {
            visibleNotifications.length > 1 && <button type="button" onClick={nextNotification} aria-label="Read next notification">
              <span className="icon-chevron-bottom" />
            </button>
          }
        </StyledIconNotificationWrapper>
        {
          visibleNotifications.length > 1 && <StyledNotificationsCloseAllWrapper
            type="button"
            onClick={handleCloseAllNotifications}
            aria-label="Close all"
          >
            <span className="icon-cancel" />{closeAllButtonText(cookies.i18next)}
        </StyledNotificationsCloseAllWrapper>
        }
        <StyledNotificationsWrapper notifications={visibleNotifications}>
          <StyledNotificationItem>
            <StyledNotificationText type={visibleNotifications[currentNotificationIndex]?.type}>
              <span className="icon-information"/>
              <b>{!/[.!?]$/.test(visibleNotifications[currentNotificationIndex]?.subject)
                ? visibleNotifications[currentNotificationIndex]?.subject + "."
                : visibleNotifications[currentNotificationIndex]?.subject}</b>
            </StyledNotificationText>
            <StyledNotificationText>
              {ReactHtmlParser(visibleNotifications[currentNotificationIndex]?.message)}
            </StyledNotificationText>
            <p>{TimestampToDateInUTCFormat(visibleNotifications[currentNotificationIndex]?.updatedAt)}</p>
            <StyledButtonClose
              onClick={() => handleCloseNotification(visibleNotifications[currentNotificationIndex]?.id)}
              disabled={closeLoading}
              aria-label="Close notification"
            ><span className="icon-cancel" />
            </StyledButtonClose>
          </StyledNotificationItem>
        </StyledNotificationsWrapper>
      </StyledNotificationsContentWrapper>) : <></>}
    </>
  );
};

export default NotificationsContainer;