import * as jwt from "jwt-simple";

export const generateJWSToken = (topic) => {
  return jwt.encode({
    "mercure": {
      "publish": [
        "*"
      ],
      "subscribe": Array.isArray(topic) ? topic : [topic]
    }
  }, process.env.REACT_APP_MERCURE_SUBSCRIBER_JWT, "HS256");
};