import i18n from "i18next";
import React, { createContext, Suspense, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import "./assets/css/main.min.css";
import "./assets/fonts/avenirNext/style.min.css";
import "./assets/fonts/cryptomc-icons/style.min.css";
import "./assets/images/flags/flag-icons.css";
import LogoutContainer from "./components/elements/logout/LogoutContainer";
import MercureTopicWrapper from "./components/elements/mercureTopicWrapper/MercureTopicWrapper";
import AutoLogoutModal from "./components/elements/modal/AutoLogoutModal";
import NotificationsContainer from "./components/elements/notification/NotificationsContainer";
import PageSpinner from "./components/elements/spinner/PageSpinner";
import LayoutProvider from "./components/layoutProvider/LayoutProvider";
import LoadRoute from "./components/loadRouter/LoadRoute";
import NetworkStatusIndicator from "./components/networkStatus/NetworkStatus";
import { GlobalStyle } from "./components/styles/globalStyle";
import { lightTheme } from "./components/styles/theme/theme";
import "./i18n";
import routes from "./routes/routes";
import userRoutesConcat from "./routes/userRoutes"; // For import CSS, use "./assets/css/main.css", after, rebuild.
import { getExpirationDate, isExpired } from "./utils/checkExpiredToken";
import { defaultLanguage, helmetHtmlByLang } from "./utils/consts";

import getUserInfo from "./utils/getUserInfo";
import { useTrafficLinksClickConversion } from "./utils/trafficLinksClickConversion";

import BackToTop from "./components/elements/backToTop/BackToTop";
import PageNotFound from "./pages/notFound/PageNotFound";
import { useCookies } from "react-cookie";

export const AuthContext = createContext({});
export const NotificationContext = createContext({});

function App() {
  const [cookies] = useCookies(["i18next"]);

  const [notifications, setNotifications] = useState([]);

  const [lngFromUrl, setLngFromUrl] = useState(defaultLanguage);

  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      setLngFromUrl(lng);
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("token") && !isExpired(getExpirationDate(localStorage.getItem("token"))));

  useEffect(() => {
    if (!authenticated && localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, [authenticated]);

  const authRouteRender = () => {
    if (!authenticated) {
      return (
        routes.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    } else {
      return (
        userRoutesConcat.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    }
  };

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, user: getUserInfo() }}>
      <NotificationContext.Provider value={{ notifications, setNotifications }}>
        <HelmetProvider>
          <Helmet>
            <html lang={lngFromUrl} />
          </Helmet>
          <ThemeProvider theme={lightTheme}>
            <MercureTopicWrapper authenticated={authenticated} />
            {useTrafficLinksClickConversion()}
            <Router>
              <Suspense fallback={<PageSpinner />}>
                <NetworkStatusIndicator />
                <AutoLogoutModal
                  authenticated={authenticated}
                  setAuthenticated={setAuthenticated}
                />
                <LayoutProvider>
                  <Switch>
                    {authRouteRender()}
                    <Redirect to={`${cookies.i18next !== defaultLanguage ?  `/${cookies.i18next}/` : "/"}404`} />
                  </Switch>
                </LayoutProvider>
              </Suspense>
              <NotificationsContainer
                authenticated={authenticated}
                notifications={notifications}
                setNotifications={setNotifications}
              />
              <LogoutContainer
                authenticated={authenticated}
              />
              <BackToTop />
            </Router>
            <GlobalStyle />
          </ThemeProvider>
        </HelmetProvider>
      </NotificationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
