export const checkCookiesAndSet = (trustedCookies, setCookie) => {
  let params = (new URL(document.location)).searchParams;

  trustedCookies.forEach(cookie => {
    if (!!params.get(cookie)) {
      setCookie(cookie, params.get(cookie), { path: "/" });
    }
  });
};

export const checkAndSetSingle = (cookieName, cookieValue, setCookie) => {
  if (!!cookieName && !!cookieValue) {
    setCookie(cookieName, cookieValue, { path: "/" });
  }
};


export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

