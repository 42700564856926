import axios from 'axios';
import { responseStatus } from "./consts";
import eventBus from "./eventBus";

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === responseStatus.HTTP_UNAUTHORIZED && localStorage.getItem("token")) {
      eventBus.dispatch("logout");
    }

    return Promise.reject(error);
  }
);