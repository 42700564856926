const eventBusMercure = {

  subscribers: {},

  on(event, callback) {
    this.subscribers = {loaded: true};
    document.addEventListener(event, callback);
  },

  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },

  remove(event, callback) {
    document.removeEventListener(event, callback);
  },

  addTopic(callback, name, params = null){
    const waitForParams = () => {
      return new Promise((resolve) => {

        const checkParams = () => {
          if (!this.subscribers.loaded) {
            setTimeout(checkParams, 100);
            return;
          }

          resolve();
        };

        checkParams();
      });
    };

    const addTopicLogic = async () => {
      await waitForParams();

      let url = params ? name + "/" + params : name;
      let data = {value: url, deleted: false};

      eventBusMercure.dispatch('mercureEvent', data)
      eventBusMercure.on(url, callback)
    };

    addTopicLogic();
  },

  removeTopic(callback, name, params = null){
    let url = params ? name + "/" + params : name;
    let data = {value: url, deleted: true};

    eventBusMercure.dispatch('mercureEvent', data)
    eventBusMercure.remove(url, callback)
  }
};

export default eventBusMercure;