import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import {
  mercureErrorTimeOut,
  mercureMultiplyErrorTimeOut,
  mercureTimeOut,
  mercureUrl,
  mercureWaitTimeOut
} from "../../../utils/consts";
import eventBusMercure from "../../../utils/eventBusMercure";
import { getMercureTopicsNewFormat, setMercureTopicsNewFormat } from "../../../utils/getMercureTopics";

const MercureTopicWrapper = ({ authenticated }) => {

  const [cookie, setCookie] = useCookies(["mercureAuthorization"]);
  const [mercureTopicObject, setMercureTopicObject] = useState({});

  const esRef = useRef(null);
  const mercureSubscribeTimeRef = useRef(null);
  const mercureErrorTimeRef = useRef(mercureErrorTimeOut);

  const mercureTopicArrayRef = useRef({});
  const mercureSubscribeTimerRef = useRef(null);

  const parseTopics = () => {
    let tempTopicArray = { ...mercureTopicObject };

    Object.keys(mercureTopicArrayRef.current).map((topic) => {
      if (!mercureTopicArrayRef.current[topic]) {
        tempTopicArray[topic] = topic;
        return null;
      }

      tempTopicArray = removeElementByKey(tempTopicArray, topic);
    });

    setMercureTopicObject(tempTopicArray);
  };

  const removeElementByKey = (object, key) => {
    let copy = { ...object };

    delete copy[key];

    return copy;
  };

  const handleEvent = (event) => {
    let data = event.detail;
    let value = data.value;

    if (mercureSubscribeTimerRef.current) {
      clearTimeout(mercureSubscribeTimerRef.current);
    }

    mercureTopicArrayRef.current[value] = data.deleted;
    mercureSubscribeTimerRef.current = setTimeout(() => {
      parseTopics();
    }, mercureWaitTimeOut);
  };

  const getUniqueObject = (value) => {
    return value.reduce((obj, value) => {
      obj[value] = value;
      return obj;
    }, {});
  };

  useEffect(() => {
    eventBusMercure.on("mercureEvent", handleEvent);

    return () => {
      eventBusMercure.remove("mercureEvent", handleEvent);
    };
  }, []);

  const mercureSubscribe = (mercureArray) => {
    setMercureTopicsNewFormat(mercureArray);

    clearTimeout(mercureSubscribeTimeRef.current);

    mercureSubscribeTimeRef.current = setTimeout(() => {
      esRef.current?.close();
      esRef.current = mercureSubscribe(mercureArray);
    }, mercureTimeOut);

    esRef.current = new EventSource(mercureUrl, { withCredentials: true });

    esRef.current.onmessage = (event) => {
      let mercuryData = JSON.parse(event.data);
      Object.keys(mercuryData).map((value) => {
        eventBusMercure.dispatch(value, mercuryData[value]);
      });
    };

    esRef.current.onerror = (event) => {
      clearTimeout(mercureSubscribeTimeRef.current);

      mercureSubscribeTimeRef.current = setTimeout(() => {
        esRef.current.close();
        esRef.current = mercureSubscribe(mercureArray);
      }, mercureErrorTimeRef.current);

      mercureErrorTimeRef.current = mercureErrorTimeRef.current * mercureMultiplyErrorTimeOut;
    };

    return esRef.current;
  };

  useEffect(() => {
    if (!Object.keys(mercureTopicObject).length) {
      esRef.current?.close();
      clearTimeout(mercureSubscribeTimeRef.current);

      return;
    }

    let mercureArray = Object.keys(mercureTopicObject);

    setCookie("mercureAuthorization", getMercureTopicsNewFormat(getUniqueObject(mercureArray)), { "path": "/" });
  }, [mercureTopicObject]);

  useEffect(() => {
    if (authenticated) {
      return;
    }

    setCookie("mercureAuthorization", "", { "path": "/" });
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      esRef.current?.close();
      clearTimeout(mercureSubscribeTimeRef.current);

      return;
    }

    if (!cookie["mercureAuthorization"]) {
      return;
    }

    let cookieMercureValue = jwt_decode(cookie["mercureAuthorization"])?.mercure?.subscribe;

    if (!cookieMercureValue || cookieMercureValue.length === 0) {
      return;
    }

    esRef.current = mercureSubscribe(getUniqueObject(cookieMercureValue));

    return () => {
      esRef.current?.close();
      clearTimeout(mercureSubscribeTimeRef.current);
    };
  }, [cookie["mercureAuthorization"]]);

  return null;
};

export default MercureTopicWrapper;