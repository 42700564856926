import styled from "styled-components";

export const StyledMainTitle = styled.h1`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  font-size: ${({ size }) => size ? `${size}px` : `64px`};
  color: ${({ color, theme }) => color || theme.primaryColors.first};
  font-weight: ${({ weight }) => weight || "750"};
  text-align: ${({ position }) => position || "left"};
  line-height: 130%;
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
    font-size: 18px;
  }
`;

export const StyledMainDescription = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  color: ${({ color, theme }) => color || theme.textColorLightBg};
  text-align: ${({ position }) => position || "left"};
`;

export const StyledTitle = styled.div`
  font-size: ${({ size }) => size ? size : "50px"};
  font-weight: 750;
  line-height: ${({ lh }) => (lh ? lh : "115%")};
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1200px) {
    font-size: 40px;
    line-height: ${({ lh }) => (lh ? lh : "100%")};
  }

  @media screen and (max-width: 992px) {
    font-size: 35px;
    line-height: ${({ lh }) => (lh ? lh : "100%")};
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 140%;
  }


  ${({decreased}) =>  decreased && `
    font-size: ${Math.floor(50 - 50*decreased/100)}px;
    
    @media screen and (max-width: 1200px) {
      font-size: ${Math.floor(40 - 40*decreased/100)}px;
    }
    @media screen and (max-width: 992px) {
      font-size: ${Math.floor(35 - 35*decreased/100)}px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  `};
`;