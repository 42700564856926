import { DateTime } from "luxon";
import "moment-timezone";
import React from "react";
import Moment from "react-moment";

const format = "DD-MM-YYYY HH:mm:ss";
const tz = "Europe/Kiev";

const TimestampToDateMoment = (str) => {
  return <Moment format={format} tz={tz}>{new Date(str * 1000)}</Moment>;
};

const DateToFormat = (str) => {
  return <Moment format={format}>{str}</Moment>;
};

const DateToTimestamp = (date) => {
  return date ? (Date.parse(date) / 1000) : null;
};

const TimestampToDate = (date) => {
  return date ? new Date(date * 1000) : "";
};

const TimestampToDateTime = (timestamp) => {
  if (!timestamp) {
    return "-";
  }

  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy, HH:mm");
};

const TimestampToDateInSlashFormat = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("yyyy/MM/dd HH:mm");
};

const TimestampToDateInUTCFormat = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("yyyy-MM-dd HH:mm:ss");
}

const DateToTimestampStart = (date) => {
  return date ? (new Date(date).setHours(0, 0, 0) / 1000) : "";
};

const DateToTimestampEnd = (date) => {
  return date ? (new Date(date).setHours(23, 59, 59) / 1000) : "";
};

const getCurrentTimeStamp = () => {
  return Math.floor((new Date()).getTime() / 1000);
};

const TimestampToDateTimeWithSeconds = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy HH:mm:ss");
};

const MillisecondsToTimestamp = (date, endOfDay = false) => {
  if (date && endOfDay) {
    date.setHours(23, 59, 59, 999);
  }

  return date ? (Date.parse(date)).toString() : "";
};

const FirstDayOfMonth = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
};

const LastDayOfMonth = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
};

const getTimeStampFuture = (date) => {
  return getCurrentTimeStamp() + date;
};

export {
  TimestampToDateMoment,
  DateToFormat,
  DateToTimestamp,
  TimestampToDate,
  TimestampToDateTime,
  TimestampToDateInSlashFormat,
  DateToTimestampStart,
  DateToTimestampEnd,
  TimestampToDateTimeWithSeconds,
  MillisecondsToTimestamp,
  FirstDayOfMonth,
  LastDayOfMonth,
  getTimeStampFuture,
  getCurrentTimeStamp,
  TimestampToDateInUTCFormat
};
