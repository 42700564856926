import { useEffect, useState } from "react";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean" ? navigator.onLine : true;

export const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus);

  useEffect(() => {
    window.addEventListener("online", () => setStatus(true));
    window.addEventListener("offline", () => setStatus(false));

    return () => {
      window.removeEventListener("online", () => setStatus(true));
      window.removeEventListener("offline", () => setStatus(false));
    };
  }, []);

  return status;
};