import styled from "styled-components";

const notificationType = {
  type: String
};

const styleNotificationIcon = (theme, type) => {
  switch (type) {
    case "NOTICE":
      return `
      color: ${theme.successColors.first};
      `;
    case "ERROR":
      return `
      color: ${theme.warningColors.first};
      `;
    case "CRITICAL":
      return `
      color: ${theme.dangerColors.first};
      `;
    case "AML":
      return `
      color: ${theme.dangerColors.first};
      `;
    default:
      return `
      color: #0078BC;
      `;
  }
};

export const StyledNotificationsWrapper = styled.ul`
  display: grid;
  place-items: center;
`;

export const StyledButtonClose = styled.button`
  padding: 5px;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  position: absolute;
  top: 3px;
  right: 3px;

  span {
    color: ${({ theme }) => theme.neutralColors.first};
  }

  &:hover {
    background-color: ${({ theme }) => theme.neutralColors.seventh};
  }
`;

export const StyledNotificationItem = styled.li`
`;

export const StyledNotificationsContentWrapper = styled.div`
  max-width: 370px;
  min-height: 80px;
  padding: 15px 25px 10px 10px;
  background-color: ${({ theme }) => theme.neutralColors.sixth};
  border: 2px solid ${({ theme }) => theme.primaryColors.fourth};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  border-radius: 10px;
  position: fixed;
  bottom: 87px;
  left: 20px;
  z-index: 1050;

  @media screen and (max-width: 520px) {
    width: 90%;
    max-width: unset ;
    bottom: 210px;
  }
`;

export const StyledNotificationsCloseAllWrapper = styled.button`
  padding: 5px 20px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.disabledColor};
  border: none;
  color: ${({ theme }) => theme.neutralColors.first};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralColors.seventh};
  }
`;

export const StyledNumbersOfNotification = styled.p`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.secondaryColors.first};
  border-radius: 50%;
  color: #09080B;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const StyledIconNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  button {
    cursor: pointer;
    padding: 5px;
    border: none;
    background-color: transparent;
    border-radius: 5px;

    span {
      color: ${({ theme }) => theme.neutralColors.first};
    }

    &:hover {
      background-color: ${({ theme }) => theme.neutralColors.seventh};
    }
  }
`;

export const StyledAlarmWrapper = styled.div`
  position: relative;

  p {
    position: absolute;
    top: -10px;
    right: -10px;
  }
`;

export const StyledNotificationText = styled("p", notificationType)`
  margin-bottom: 5px;
  word-break: break-word;

  & > span {
    margin-right: 7px;
    font-size: 20px;
    position: relative;
    top: 3px;
    ${({ theme, type }) => styleNotificationIcon(theme, type)};
  }
`;