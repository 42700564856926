import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import notFoundPageImage from "../../assets/images/not-found-page.webp";

import { StyledParagraph } from "../../components/styles/styledDocumentElements";
import { StyledButton } from "../../components/styles/styledButton";
import { StyledTitle } from "../../components/styles/styledTitle";
import { StyledImageWrapper, StyledNotFoundContentWrapper, StyledNotFoundWrapper } from "./styledPageNotFound";
import CustomLink from "../../components/customLink/CustomLink";
import { helmetHtmlByLang } from "../../utils/consts";

const PageNotFound = () => {
  const { t } = useTranslation("notFoundPage");

  return (
    <>
      <Helmet>
        <title>{t("mainTitle")} | Acceptcoin</title>
        <meta
          content={t("mainTitle")}
          property="og:title"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <link rel="canonical" href={window.location.href} />
        <link rel="alternate" href={"https://" + window.location.hostname + "/404"} hrefLang={helmetHtmlByLang.en} />
        <link rel="alternate" href={"https://" + window.location.hostname + "/ru/404"} hrefLang={helmetHtmlByLang.ru} />
        <link rel="alternate" href={"https://" + window.location.hostname + "/ua/404"} hrefLang={helmetHtmlByLang.ua} />
      </Helmet>
      <StyledNotFoundWrapper>
        <StyledNotFoundContentWrapper>
          <div className="not-found-info">
            <StyledTitle>
              {t("mainTitle")}
            </StyledTitle>
            <StyledParagraph>
              <Trans components={{ CustomLink: <CustomLink to="/contacts" /> }}>
                {t("text")}
              </Trans>
            </StyledParagraph>
            <StyledButton
              className="not-found__go-homepage"
              size="xl"
              as={CustomLink}
              to="/"
              aria-label={t("goHomePage")}
            >
              {t("goHomePage")} <span className="icon-arrow-right" />
            </StyledButton>
          </div>
          <StyledImageWrapper>
            <LazyLoadImage
              width={631}
              height={292}
              src={notFoundPageImage}
              alt={t("mainTitle")}
              visibleByDefault={true}
            />
          </StyledImageWrapper>
        </StyledNotFoundContentWrapper>

      </StyledNotFoundWrapper>
    </>
  );
};

export default PageNotFound;
