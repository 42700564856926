import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import { defaultLanguage } from "../../utils/consts";

const CustomLink = ({ children, to, className, activeClassName, onClick, target, title }) => {

  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;

  return (
    <NavLink
      to={`${language}${to}`}
      className={className}
      activeClassName={activeClassName}
      onClick={onClick}
      target={target}
      title={title}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;