import styled from "styled-components";

const styledList = (as, theme) => {
  switch (as) {
    case 'ol':
      return `
        counter-reset: item;
        li:before {
          content: counter(item) ".";
          counter-increment: item;
          font-weight: 700;
        }
      `;
    default:
      return `
        li:before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${theme.secondaryColors.first};
          top: 8px;
        }
      `;
  }
}
// lower-alpha
export const StyledList = styled('ul')`
  margin-bottom: 15px;
  ${({ as, theme }) => styledList(as, theme)}
  
  li {
    margin-bottom: 10px;
    padding-left: 22px;
    position: relative;
    ${({countStart}) => countStart && `
      padding-left: 36px;
      &::before {
        content: "${countStart}." counter(item) ".";
    }`};
    
    p, li {
      opacity: 1;
    }
    &::before {
      position: absolute;
      left: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  b {
    padding-bottom: 7px;
    display: block;
  }
  ${({listStyle}) => listStyle === 'alpha' &&
  `list-style: lower-alpha;
       padding-left: 22px;
       li {padding-left: 5px;}
       li:before {display: none;}
       li::marker {font-weight: 700;}
  ` };
`;

export const StyledDecorationElement = styled.span`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  max-width: ${({maxWidth}) => maxWidth || "100%" };
  display: ${({display}) => display || "inline" };
  text-align: ${({position}) => position ? position : 'inherit'};
  font-weight: ${({ weight }) => weight || "400"};
  font-size: ${({ size }) => size ? `${size}px` : "14px"};
  color: ${({ color, theme }) => color || theme.textColorLightBg};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ underline }) => underline && `text-decoration: underline`};
  ${({ wrap }) => wrap && `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`;

export const StyledSubTitle = styled.h3`
  color: ${({theme}) => theme.secondaryColors.first};
  font-size: 24px;
  font-weight: 750;
  line-height: 170%;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 140%;
  }
`;

export const StyledParagraph = styled.p`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  font-size: ${({decreased}) => decreased ? (18 - Math.ceil(decreased/100*18)) : 18}px;
  font-weight: 400;
  line-height: 170%;
  b {
    padding-bottom: 7px;
  }
  b.block {
    display: block;
  }
  strong {
    font-weight: inherit;
  }
  strong.bold {
    font-weight: 700;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 140%;
  }
`;

export const StyledDocumentImage = styled.div`
  ${({center}) => center && 'margin: 0 auto;'};
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({width}) => width && `max-width: ${width}px`};
  img {
    width: 100%;
    height: auto;
  }
`;