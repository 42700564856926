import styled from "styled-components";

const size = {
  size: ""
};

const changeContainerSize = (size) => {
  switch (size) {
    case "sm":
      return `
        max-width: 1140px
      `;
    default:
      return `
        max-width: 1470px
      `;
  }
};

export const StyledContainer = styled("div", size)`
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
`;

export const StyledContentWrapper = styled.div`
  min-height: ${({ height }) => height === "auto" ? "auto" : "calc(100vh - 85px - 330px)"};
  margin: 20px 0;
  padding: 20px;
  background-color: ${({theme}) => theme.textColorDarkBg};
  border-radius: 15px;
  box-shadow: 0 3px 12px rgba(113, 122, 134, 0.3);

  @media screen and (max-width: 992px) {
    margin: 48px 0 20px;
  }
  @media screen and (max-width: 599px) {
    margin: 43px 0 15px;
    padding: 15px;
  }
`;

export const StyledInformationContainer = styled.div`
  max-width: ${({ width }) => width + "px" || "none"};
  width: 100%;

  .information {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 10px;
    border-bottom: 1px solid #F1F1F1;
    word-break: break-all;

    &__value {
      width: 100%;
      color: #193636;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    &__key {
      word-break: break-word;
    }

    &__value.code {
      background-color: ${({ theme }) => theme.neutralColors.seventh};
      padding: 10px;
      border-radius: 7px;
    }

    &__value.with-bgColor {
      padding: 10px;
      background-color: ${({ theme }) => theme.neutralColors.sixth};
    }

    &__value.share-link {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;
      & > a {
        cursor: pointer;
        
        & > img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .trusted-ip-addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    max-width: none;
    .information {
      padding: 12px 0;
      grid-template-columns: 100%;

      &__key {
        padding-bottom: 10px;
        font-weight: 600;
      }
    }
  }
`;

export const StyledGridInfoBlock = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 20% 80%;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledBlockLabel = styled.div`
  color: #193636;
  font-size: 16px;
  font-weight: 600;
  ${({ padding }) => padding && `padding: ${padding}`};
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const StyledMainWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledMainContainer = styled.div`
  padding-top: 80px;
  flex: 1 1 auto;
  @media screen and (max-width: 992px) {
    padding-top: 65px;
  }
`;

export const StyledSectionOverlay = styled.div`
  width: 100%;
  color: ${({theme}) => theme.textColorDarkBg};
  border-left-width: 100vw;
  border-color: transparent;
  border-bottom: 145px;
  border-top: 0px solid transparent;
  border-style: solid;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media screen and (max-width: 768px) {
    border-bottom: 85px;
    border-style: solid;
  }
`;