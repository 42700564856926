import axios from "axios";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { languages } from "./consts";
import { checkCookiesAndSet, getCookie } from "./cookies.utils";
import userAuthenticationConfig from "./userAuthenticationConfig";

export const useTrafficLinksClickConversion = () => {
  let urlParams = (new URL(document.location)).searchParams;

  const trustedCookies = ["refToken", "traffic"];
  const [cookies, setCookie] = useCookies(trustedCookies);

  const updateTrafficLinks = () => {
    axios.put("/api/update-click-conversion/" + urlParams.get("traffic"), {}, userAuthenticationConfig()).then().catch();
  };

  useEffect(() => {
    checkCookiesAndSet(trustedCookies, setCookie);

    if (!!urlParams.get("traffic")) {
      updateTrafficLinks();
    }

    if (!!urlParams.get("refToken")) {
      updateTrafficLinks();

      let currentUrl = new URL(document.location);

      let lang = getCookie(["i18next"]) === languages.EN ? '' : getCookie(["i18next"]);

      window.location.replace(currentUrl.origin + "/" + lang);
    }
  }, []);
};