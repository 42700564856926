import styled from "styled-components";

const positionTemplate = {
  position: String,
}

const stylePosition = (position) => {
  switch (position) {
    case 'center':
      return `
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      `;
    default:
      return `
        
      `;
  }
}

export const StyledSpinner = styled('div', positionTemplate)`
  ${({position}) => stylePosition(position)}
`;

export const StyledFragmentSpinner = styled('div', positionTemplate)`
  ${({position}) => stylePosition(position)};
  border-radius: ${({radius}) => radius ? `${radius}px` : `15px`};
  background-color: ${({background}) => !background ? `rgba(255, 255, 255, 0.6);` : `transparent` };
`;

export const StyledLoadButton = styled.div`
  display: inline-grid;
  text-align: left;
  position: relative;
  .loading-button {
    text-align: inherit;
    opacity: 0.75;
    &__spinner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyledPageSpinner = styled.div`
  padding: 50px 0;
`;