import { mercureTopicOrigin, mercureUrl } from "./consts";
import { generateJWSToken } from "./mercureAuth";

export const getMercureTopics = (route, data = null) => {
  const topics = [];
  mercureUrl.searchParams.delete("topic");

  if (data) {
    for (let i = 0; i < data.length; i++) {
      topics.push(...appendTopic(route, data[i].id));
    }

    return generateJWSToken(topics);
  }

  topics.push(...appendTopic(route, '{id}'));
  return generateJWSToken(topics);
};

const appendTopic = (route, id) =>{
  const topics = [];

  let topic = `${mercureTopicOrigin}/api/${route}`;

  if (id){
    topic = `${mercureTopicOrigin}/api/${route}/${id}`;
  }

  mercureUrl.searchParams.append("topic", topic);
  topics.push(topic);

  return topics;
}

const appendTopicForUrl = (route, id) =>{
  const topics = [];

  let topic = `${mercureTopicOrigin}/api/${route}`;

  if (id){
    topic = `${mercureTopicOrigin}/api/${route}/${id}`;
  }

  mercureUrl.searchParams.append("topic", topic);
  topics.push(topic);

  return topics;
}

export const getMercureTopicsNewFormat = (data) => {
  const topics = [];
  mercureUrl.searchParams.delete("topic");

  Object.keys(data).map((value) => {
    topics.push(...appendTopic(value, data[value]?.id));
  })

  return generateJWSToken(topics);
};

export const setMercureTopicsNewFormat = (data) => {
  mercureUrl.searchParams.delete("topic");

  Object.keys(data).map((value) => {
    mercureUrl.searchParams.append("topic", value);
  })
};
