import styled from "styled-components";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (theme, color, border, weight) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? theme.primaryColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.primaryColors.first};
        border: 2px solid ${theme.primaryColors.first};
        &:hover {
          background-color: ${border ? theme.primaryColors.eighth : theme.primaryColors.second};
          border: 2px solid ${theme.primaryColors.second};
        }
    `;
    case "success":
      return `
        color: ${border ? theme.successColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.successColors.first};
        border: 2px solid ${theme.successColors.first};
        &:hover {
          background-color: ${border ? theme.successColors.fourth : theme.successColors.second};
          border: 2px solid ${theme.successColors.second};
        }
     `;
    case "danger":
      return `
        color: ${border ? theme.dangerColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.dangerColors.second};
        border: 2px solid ${theme.dangerColors.first};
        &:hover {
          background-color: ${border ? theme.dangerColors.fourth : theme.dangerColors.second};
          border: 2px solid ${theme.dangerColors.second};
        }
      `;
    case "warning":
      return `
        color: ${border ? theme.warningColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.warningColors.first};
        border: 2px solid ${theme.warningColors.first};
         &:hover {
          background-color: ${border ? theme.warningColors.fourth : theme.warningColors.second};
          border: 2px solid ${theme.warningColors.second};
        }
     `;
    case "white":
      return `
        color: ${border ? "#fff" : "#262626"};
        background-color: ${border ? "transparent" : "#fff"};
        border: 2px solid #fff;
        &:hover {
          background-color: ${border ? "rgba(58, 87, 87, 0.2)" : "#fff"};
          border: 2px solid #fff;
        }
      `;
    default:
      return `
        color: ${border ? theme.secondaryColors.first : theme.neutralColors.first};
        background-color: ${border ? "transparent" : theme.secondaryColors.first};
        border: 2px solid ${theme.secondaryColors.first};
        &:hover {
          background-color: ${border ? theme.secondaryColors.fifth : theme.secondaryColors.second};
          border: 2px solid ${theme.secondaryColors.second};
        }
      `;
  }
};

const buttonSize = (size) => {
  switch (size) {
    case "xl":
      return `
        padding: 22px 46px;
        font-size: 25px;
        line-height: 100%;
        border-radius: 20px;
        @media screen and (max-width: 992px) {
          padding: 16px 30px;
          font-size: 18px;
          border-radius: 10px;
        }
      `;
    case "x":
      return `
      padding: 18px 36px;
      font-size: 20px;
      line-height: 100%;
      border-radius: 15px;
        @media screen and (max-width: 992px) {
          padding: 16px 30px;
          font-size: 18px;
          border-radius: 10px;
        }
      `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  padding: ${({ figure }) => figure === "circle" ? "12px" : "8px 26px"};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "10px"};
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.7;
    transform: scale(1) !important;
  `};

[ class ^= "icon-" ], [ class *= " icon-" ] {
  margin-left: 10px;
}

  ${({ theme, color, border, weight }) => styleButton(theme, color, border, weight)}
  ${({ size }) => buttonSize(size)}
  &:active {
    transform: scale(0.948) translateZ(0);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
  }
`;