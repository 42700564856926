import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import eventBus from "../../../utils/eventBus";
import eventBusMercure from "../../../utils/eventBusMercure";
import getUserInfo from "../../../utils/getUserInfo";
import { closableNotification } from "../notification/ClosableNotification";

const LogoutContainer = ({ authenticated }) => {
  const { t } = useTranslation("autoLogout", { useSuspense: false });

  const fetchLogout = (event) => {
    if (event.detail.message) {
      closableNotification(t("notifications." + event.detail.message), "error");
    }

    eventBus.dispatch("logout");
  };

  let params = null;

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    params = getUserInfo()["userId"];

    eventBusMercure.addTopic(fetchLogout, "logout-all-seances", params);

    return () => {
      eventBusMercure.removeTopic(fetchLogout, "logout-all-seances", params);
    };
  }, [authenticated]);

  return (<></>);
};

export default LogoutContainer;