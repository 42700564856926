import { createGlobalStyle } from "styled-components";

const mainFont = "Avenir Next Cyr, serif";
const mainFontSize = "16px";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;

    &::selection {
      background-color: ${({ theme }) => theme.secondaryColors.sixth};
    }
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.textColorLightBg};
    font-family: ${mainFont};
    font-size: ${mainFontSize};
    line-height: 20px;
  }

  h1, h2, h3, h4, h5, h6, p, span, select, input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  input, textarea {
    color: #262626;
  }

  input, select, textarea {
    @media screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }

  button {
    padding: 0;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
  }

  #root {
    height: 100%;
  }

  .default-link {
    color: ${({ theme }) => theme.secondaryColors.first};

    &:hover {
      text-decoration: underline;
    }
  }

  .green {
    color: ${({ theme }) => theme.successColors.first};
  }

  .red {
    color: ${({ theme }) => theme.dangerColors.first};
  }

  //Progressbar custom style
  #nprogress .bar {
    height: 2px;
    background: ${({ theme }) => theme.secondaryColors.first};
    z-index: 1999;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${({ theme }) => theme.secondaryColors.first}, 0 0 5px ${({ theme }) => theme.secondaryColors.first};
  }

  #nprogress .spinner-icon {
    display: none;
    border-top-color: ${({ theme }) => theme.secondaryColors.first};
    border-left-color: ${({ theme }) => theme.secondaryColors.first};
  }

  //AntDesign dialog window
  .default-modal-wrapper {
    z-index: 100001;

    .default-modal__autologout {
      border: 2px solid ${({ theme }) => theme.primaryColors.fourth};
      border-radius: 6px;
      box-shadow: 0px 1px 4px rgba(25, 54, 54, 0.5);
    }

    .rc-dialog-close {
      color: #193636;
      font-size: 12px;
      text-shadow: none;
      top: 20px;
      outline: none;
      z-index: 1;
      transition: all .3s ease;

      &-x:after {
        content: "\\e910";
        font-size: 12px;
        font-family: 'cryptomc-icons', serif;
      }
    }

    .rc-dialog-header {
      padding: 15px 20px;
      border: none;

      .rc-dialog-title {
        color: #193636;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #F1F1F1;
          position: absolute;
          bottom: -15px;
          left: 0;
        }
      }
    }

    .rc-dialog-body {
      padding: 20px;
    }

    .default-modal {
      top: 100px;

      &__content {
        p {
          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }

      &__footer {
        margin-top: 20px;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(75px, max-content));
        grid-gap: 15px;
        justify-content: end;
        border-top: 1px solid #F1F1F1;
      }
    }

    .scroll-content {
      max-height: 560px;
      overflow-y: auto;

      & > div {
        max-width: 545px;
        margin: 0 auto 15px;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        background-color: #ECEEEE;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        width: 10px;
        border: 2px solid #ECEEEE;
        background-color: ${({ theme }) => theme.primaryColors.first};
        border-radius: 12px;
      }
    }

    .exchanger-modal {
      max-width: 860px;
      width: 100%;

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }
  }

  .default-modal-wrapper_confirm {
    .rc-dialog-close {
      display: none;
    }

    .confirm-modal {
      max-width: 400px;
      margin: 10px auto;

      &__content {
        padding-top: 70px;
        position: relative;

        &::before {
          content: "\\e957";
          width: 50px;
          height: 50px;
          color: #FEB967;
          font-size: 50px;
          font-family: 'cryptomc-icons', serif;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @media screen and (max-width: 576px) {
        max-width: inherit;
        margin: 10px;
      }
    }
  }

  //AntDesign drawer custom style
  .drawer.drawer-open .drawer-mask {
    opacity: 0.45;
  }

  .drawer-left .drawer-content {
    background-color: #244D4D;
  }

  .drawer-right .drawer-content {
    background: ${({ theme }) => theme.primaryColors.first};
  }

  .drawer-right.drawer-open {
    z-index: 100000;
  }

  .tooltip-width-250 {
    max-width: 250px;

    @media screen and (max-width: 340px) {
      max-width: 235px;
    }
  }

  //AntDesign notifications
  .rc-notification {
    top: 93px !important;
    padding: 0;
    transform: translateX(-50%);
    z-index: 100002;

    @media screen and (max-width: 992px) {
      top: 108px !important;
    }
    @media screen and (max-width: 600px) {
      top: 101px !important;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: inherit;
      left: 0 !important;
    }

    .rc-notification-notice-close {
      color: black;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .rc-notification-notice {
      margin: 0;
      padding: 5px 20px 0 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }

    .message-with-icon {
      min-height: 50px;
      padding: 15px 15px 15px 55px;
      color: #262626;
      display: inline-flex;
      border-radius: 5px;
      position: relative;

      &::before {
        width: 25px;
        height: 25px;
        font-size: 22px;
        font-family: 'cryptomc-icons', serif;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        left: 15px;
      }
    }

    .message-with-icon.success {
      background-color: #DCF0D9;

      &::before {
        content: '\\e91a';
        color: #4fba6e;
      }
    }

    .message-with-icon.error {
      background-color: #FFD0D3;

      &::before {
        content: '\\e905';
        color: #FE6A74;
      }
    }

    .message-with-icon.help {
      background-color: #F8F7EE;

      &::before {
        content: '\\e92f';
        color: #FEB967;
      }
    }

    .message-with-icon.info {
      background-color: #D6EFFF;

      &::before {
        content: '\\e930';
        color: #3286d9;
      }
    }

    .message-with-icon.warning {
      background-color: #FEF2D5;

      &::before {
        content: '\\e957';
        color: #FEB967;
      }
    }
  }

  //AntDesign custom tabs
  .rc-tabs-dropdown {
    min-width: 200px;
    padding: 7px;
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(113, 122, 134, 0.3);
    border-radius: 10px;
    border: none;

    li {
      width: 100%;
      padding: 10px 12px;
      color: ${({ theme }) => theme.primaryColors.first};
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      border-radius: 5px;
      border: none;
      outline: none;
      display: flex;
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.primaryColors.first};
        background: rgb(0 27 80 / 5%);
      }
    }
  }

  .default-tabs {
    border: none;
    grid-gap: 20px;

    .rc-tabs-nav-more {
      position: relative;
      background-color: transparent;
      border-radius: 30px;

      &:hover {
        background-color: rgb(0 27 80 / 5%);
      }

      span {
        transform: rotate(90deg);
        position: absolute;
        font-size: 26px;
        top: 15px;
        left: -1px;
      }
    }

    .rc-tabs-nav-list {
      .rc-tabs-tab {
        font-size: 14px;
        position: relative;

        &:hover {
          opacity: 1;
        }

        &.attention-tab-notice::before,
        &.attention-tab-info::before,
        &.attention-tab-error::before,
        &.attention-tab-warning::before,
        &.attention-tab-frozen_due_aml::before {
          content: "\\e93d";
          width: 11px;
          height: 20px;
          padding: 0 5px;
          font-family: 'cryptomc-icons', serif;
          color: ${({ theme }) => theme.neutralColors.first};
          background-color: ${({ theme }) => theme.secondaryColors.first};
          font-size: 12px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: 2px;
        }

        .rc-tabs-tab-btn {
          outline: none;
        }
      }

      .rc-tabs-tab-active {
        opacity: 1;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 3px;
          background: ${({ theme }) => theme.primaryColors.first};
          position: absolute;
          left: 0;
          bottom: -8px;
          transform-origin: left;
          animation: scale-in-top 0.3s ease-in-out forwards;

          @media screen and (max-width: 576px) {
            bottom: 0px;
          }
        }

        @keyframes scale-in-top {
          0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            opacity: 1;
          }
        }
      }

      .rc-tabs-ink-bar {
        display: none;
      }

      @media screen and (max-width: 576px) {
        display: grid;
        grid-template-rows: auto;
        .rc-tabs-tab {
          margin: 0px;
          padding: 5px 15px;
        }

        .rc-tabs-ink-bar {
          display: none;
        }

        .rc-tabs-tab-active {
          color: #FFFFFF;
          font-weight: 700;
          background-color: rgba(25, 54, 54, 1);
          border: 1px solid #193636;
          border-radius: 5px;
        }
      }
    }

    .rc-tabs-content-holder {
      .rc-tabs-tabpane {
        outline: none;
      }
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, auto);
    }
  }

  .default-tabs-left {
    display: grid;
    grid-template-columns: 256px auto;

    .rc-tabs-tab {
      margin: 0px;
      padding: 5px 15px;
    }

    .rc-tabs-ink-bar {
      display: none;
    }

    .rc-tabs-tab-active {
      color: #FFFFFF;
      font-weight: 700;
      background-color: rgba(25, 54, 54, 1);
      border: 1px solid #193636;
      border-radius: 5px;
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }

  .default-tabs-top {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 55px auto;
    overflow: inherit;

    .rc-tabs-nav-list {
      width: 100%;
      border-bottom: 1px solid #8C9B9B;
    }

    .rc-tabs-tab {
      margin-bottom: 8px;
      padding: 15px 15px;
      color: ${({ theme }) => theme.neutralColors.first};
      font-weight: 400;
      background: transparent;
      opacity: 0.85;

      &:last-child {
        margin: 0;
      }
    }

    .rc-tabs-tab-active {
      color: #FFFFFF;
      background-color: ${({ theme }) => theme.primaryColors.first};
      border-radius: 5px;
      font-weight: 700;
    }

    .rc-tabs-nav-more {
      min-width: 25px;
      background-color: transparent;
      border: none;
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      grid-template-rows: minmax(40px, auto) auto;
    }
  }

  //AntDesign custom submenu 
  .card-submenu {
    background-color: #595959;
    border-color: #595959;
    box-shadow: none;

    .rc-dropdown-menu-item {
      padding: 0;

      a, button {
        width: 100%;
        padding: 7px 10px;
        color: #fff;
        font-weight: 700;
        display: inline-grid;
        grid-template-columns: 14px max-content;
        grid-gap: 10px;
        text-align: left;
        border: none;
        outline: none;

        span {
          font-size: 14px;
          opacity: 0.5;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    .rc-dropdown-menu-item-selected {
      background-color: transparent;

      &::after {
        display: none;
      }
    }
  }

  //AntDesign select
  .show-all-items .rc-virtual-list-holder {
    max-height: 240px !important;
  }

  .rc-select-item-empty {
    padding: 16px 22px;
    background: #F3F3F3;
  }

  .rc-select-selector {
    min-height: 50px;

    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        display: inline-flex;
      }
    }

    span.rc-select-selection-item, span.rc-select-selection-placeholder {
      padding: 10px 22px;
      display: flex;
    }
  }

  .rc-select-dropdown {
    border: 1px solid #8C9B9B;
    box-shadow: 0 1px 4px rgb(25 54 54 / 50%);
    z-index: 1000;

    .rc-select-item-option {
      padding: 10px 20px;
      background: #F3F3F3;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.textColorDarkBg};
        background: ${({ theme }) => theme.primaryColors.first};
      }

      .option-select-item {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        span {
          padding-right: 20px;
          font-size: 22px;
        }

        img {
          height: 22px;
          width: 22px;
          margin-right: 20px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .rc-select-item-option-selected {
      color: ${({ theme }) => theme.textColorDarkBg};
      background: ${({ theme }) => theme.primaryColors.first};

      .rc-select-item-option-state {
        display: none;
      }

    }
  }

  //AntDesign custom checkbox
  .default-checkbox {
    z-index: 1;

    input {
      width: 17px;
      height: 17px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rc-checkbox-inner {
      width: 17px;
      height: 17px;
      border-color: transparent;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(25 54 54 / 50%);

      &::after {
        border: none
      }
    }

    @media screen and (max-width: 992px) {
      z-index: auto;
    }
  }

  .rc-checkbox-checked {
    .rc-checkbox-inner {
      background-color: ${({ theme }) => theme.primaryColors.first};

      &::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  //AntDesign custom switch
  .default-switch {
    border: 1px solid #193636;
    background-color: #FFFFFF;

    &::after {
      background-color: ${({ theme }) => theme.primaryColors.first};
    }
  }

  .rc-switch-checked {
    border: 1px solid #193636;
    background-color: ${({ theme }) => theme.primaryColors.first};

    &::after {
      background-color: #FFFFFF;
    }
  }


  //AntDesign custom pagination 
  .default-pagination {
    padding: 30px 0;
    display: flex;
    justify-content: center;


    li[aria-disabled="true"] {
      display: none;
    }

    button[aria-label="prev page"] {
      &::after {
        content: '\\e903';
        display: block;
        font-family: 'cryptomc-icons', serif;
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.neutralColors.first};
      }
    }

    .rc-pagination-next {
      button[aria-label="next page"] {
        &::after {
          content: '\\e904';
          display: block;
          font-family: 'cryptomc-icons', serif;
          font-size: 16px;
          font-weight: 600;
          color: ${({ theme }) => theme.neutralColors.first};
        }
      }
    }

    .rc-pagination-jump-next, .rc-pagination-jump-prev {
      button[aria-label="next page"], button[aria-label="prev page"] {
        &::after {
          content: "...";
          margin-left: 5px;
          display: inline-block;
          color: black;
          font-weight: 800;
        }
      }
    }

    .rc-pagination-item, .rc-pagination-item-link {
      color: rgba(140, 155, 155, 0.5);;
      background-color: transparent;
      border: none;
      border-radius: 5px;
      outline: none;

      a {
        color: ${({ theme }) => theme.neutralColors.first};
      }

      &:hover {
        color: ${({ theme }) => theme.secondaryColors.first};
      }
    }

    .rc-pagination-item-active {
      color: ${({ theme }) => theme.neutralColors.first};
      background: ${({ theme }) => theme.secondaryColors.first};
      border-radius: 5px;
    }

    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

  .lazy-image {
    width: auto !important;
    height: auto !important;
  }

  .cvs-file {
    &-block {
      margin-top: 15px;

      ol {
        margin-left: 15px;
        list-style: auto;
      }

      .csv-reader-input {
        margin: 10px 0;

        .csv-input {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }

        .csv-label {
          padding: 5px 8px;
          border-radius: 5px;
          cursor: pointer;
          color: #fff;
          background-color: ${({ theme }) => theme.primaryColors.first};
          border: 2px solid #193636;
        }

        .csv-label:before {
          content: "\\e924";
          margin-right: 10px;
          color: #fff;
          font-size: 14px;
          font-family: "cryptomc-icons", serif;
        }
      }

      &__example {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    span {
      font-weight: 700;
      margin-right: 8px;
    }
  }

  @keyframes loadContent {
    0% {
      opacity: 0;
      transform: translateY(-25px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  //captcha
  .grecaptcha-badge {
    opacity: 0;
  }

  .total-balances, .all-transactions {
    &__chart {
      .canvasjs-chart-canvas {
        height: 275px !important;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .rc-tooltip {
    z-index: 100001;
  }
`;