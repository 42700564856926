import styled from "styled-components";

export const StyledBackToTopButton = styled.button`
  width: 80px;
  height: 80px;
  color: #fff;
  font-size: 30px;
  text-align: center;
  border: none;
  border-radius: 25px;
  transition: all .3s ease;
  ${({ isVisible }) => isVisible ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;'};
  position: fixed;
  bottom: 80px;
  right: 20px;
  cursor: pointer;
  z-index: 10000;

  @media screen and (max-width: 992px) {
    width: 50px;
    height: 50px;
    bottom: 80px;
  }

  img {
    width: 40px;
    height: 58px;
    position: relative;
    z-index: 10000;

    @media screen and (max-width: 992px) {
      width: 32px;
      height: 48px;
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 0.75;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(15px);
  }

  .progress {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    transform: rotate(-90deg);
    position: absolute;
    top: -27px;
    left: -27px;
    pointer-events: none;

    @media screen and (max-width: 992px) {
      width: 110px;
      height: 110px;
      top: -31px;
      left: -31px;
    }

    .background {
      stroke-width: 3;
      stroke: ${({ theme }) => theme.secondaryColors.first};
      fill: none;
      opacity: 0.2;
    }

    .chart {
      stroke-width: 3;
      stroke: ${({ theme }) => theme.secondaryColors.first};
      fill: none;
    }

    .inside-line {
      stroke-width: 0.3;
      stroke: ${({ theme }) => theme.secondaryColors.first};
      fill: none;
    }
  }
`;

