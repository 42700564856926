import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigatorOnLine } from "../../utils/networkStatus";
import { closableNotification } from "../elements/notification/ClosableNotification";

const NetworkStatusIndicator = () => {
  const { t } = useTranslation("notifications");

  const isOnline = useNavigatorOnLine();

  useEffect(() => {
    if (!isOnline) {
      closableNotification(t("notifications.offline"), "error");
    }
  }, [isOnline]);

  return null;
};

export default NetworkStatusIndicator;