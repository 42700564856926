import React, { memo, useContext, useEffect, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";
import nprogress from "nprogress";
import eventBus from "../../utils/eventBus";
import { AuthContext } from "../../App";

const LoadRoute = (props) => {
  const history = useHistory();
  const { setAuthenticated } = useContext(AuthContext)

  const handleOnIdle = () => {
    eventBus.on("logout", () => {
      history.push("/");
      localStorage.removeItem("token");
      setAuthenticated(false);
    });

    window.addEventListener("storage", function (event) {
      if (event.key === "token" && !localStorage.getItem("token")) {
        eventBus.dispatch("logout");
      }
    });
  };

  useMemo(() => {
    nprogress.start();
  }, []);

  useEffect(() => {
    nprogress.done();
  }, []);

  useEffect(() => {
    handleOnIdle();
  }, []);

  return <Route {...props} />;
};

export default memo(LoadRoute);
