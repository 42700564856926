import React, { lazy, Suspense, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import PageSpinner from "../elements/spinner/PageSpinner";
import routes from "../../routes/routes";
import { footerExceptions } from "../../utils/consts";

import { StyledMainContainer, StyledMainWrapper } from "../styles/styledContainer";
import i18n from "../../i18n";

const Header = lazy(() => import("../elements/header/Header"));
const Footer = lazy(() => import("../elements/footer/Footer"));

const LayoutProvider = ({ history, children }) => {
  const refHistoryLocationPathName = useRef(history.location.pathname);

  useEffect(() => {
    const unlisted = history.listen(() => {

      refHistoryLocationPathName.current = history.location.pathname;

      let checkRoute;

      routes.map((item) => {
        checkRoute = item.path !== refHistoryLocationPathName.current;
      });

      if (refHistoryLocationPathName.current !== history.location.pathname || checkRoute) {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisted();
    };
  }, []);

  const examinationPageForRenderFooter = history.location.pathname.includes(footerExceptions.LOGIN) || history.location.pathname.includes(footerExceptions.REGISTRATION);

  return (
    <StyledMainWrapper>
      <Suspense fallback={<PageSpinner />}>
        <Header />
        <StyledMainContainer>
          {children}
        </StyledMainContainer>
        {!examinationPageForRenderFooter && <Footer />}
      </Suspense>
    </StyledMainWrapper>
  );
};

export default withRouter(LayoutProvider);