export default {
  primaryColors: {
    first: "#193636",
    second: "#3A5757",
    third: "#5B7778",
    fourth: "#7C989A",
    fifth: "#9CB8BB",
    sixth: "#BDD9DC",
    seventh: "#DEF9FD",
    eighth: "rgba(58,87,87,0.1)",
    nine: "rgba(25, 54, 54, 0.05)",
    ten: "rgba(25, 54, 54, 0.3)",
  },
  secondaryColors: {
    first: "#E9B03E",
    second: "#F0C76A",
    third: "#F8DD96",
    fourth: "#FFF4C2",
    fifth: "rgba(233, 176, 62, 0.1)",
    sixth: "rgba(233, 176, 62, 0.5)"
  },
  neutralColors: {
    first: "#252525",
    second: "#404040",
    third: "#757575",
    fourth: "#B5B5B5",
    fifth: "#B5B5B5",
    sixth: "#F5F5F5",
    seventh: "#E8EBEB"
  },
  dangerColors: {
    first: "#D01E29",
    second: "#DE2B36",
    third: "#F3EAEB",
    fourth: "rgba(208, 30, 41, 0.1)",
    fifth: "rgba(208, 30, 41, 0.4)"
  },
  successColors: {
    first: "#008744",
    second: "#0F9854",
    third: "#F7FBF9",
    fourth: "rgba(0, 135, 68, 0.1)",
    fifth: "rgba(0, 135, 68, 0.4)"
  },
  warningColors: {
    first: "#FF7C35",
    second: "#FB8749",
    third: "#FFFBE5",
    fourth: "rgba(255, 124, 53, 0.1)"
  },
  exchangeColor: "#0079BD",
  disabledColor: "#F5F5F5",
  textColorLightBg: "#193636",
  textColorDarkBg: "#FFFFFF",
  blockColorDarkBg: "#FFFFFF"
};