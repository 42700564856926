import { lazy } from "react";
import { supportedLanguages } from "../utils/consts";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const RegistrationPage = lazy(() => import("../pages/registration/RegistrationPage"));
const ExamplePage = lazy(() => import("../pages/example/ExamplePage"));
const PageNotFound = lazy(() => import("../pages/notFound/PageNotFound"));
const EmailConfirm = lazy(() => import("../pages/emailConfirm/EmailConfirmPage"));
const ForgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPasswordPage"));
const ChangePassword = lazy(() => import("../pages/changePassword/ChangePasswordPage"));
const ClientNewsPage = lazy(() => import("../pages/news/client/ClientNewsPage"));
const ClientNewsDetails = lazy(() => import("../pages/news/client/ClientDetailsNewsPage"));
const PaymentPage = lazy(() => import("../pages/paymentPage/PaymentPage"));
const AboutUsPage = lazy(() => import("../pages/aboutUs/AboutUsPage"));
const ContactsPage = lazy(() => import("../pages/contacts/ContactsPage"));
const ServicesPage = lazy(() => import("../pages/services/ServicesPage"));
const ServiceDetailsPage = lazy(() => import("../pages/services/ServiceDetailsPage"));
const ServicesIndustriesPage = lazy(() => import("../pages/servicesIndustries/ServicesIndustriesPage"));
const ServicesIndustriesDetailPage = lazy(() => import("../pages/servicesIndustries/ServicesIndustriesDetailPage"));
const PricePage = lazy(() => import("../pages/pricePage/PricePage"));
const LoyaltyProgramApprovalPage = lazy(() => import("../pages/loyaltyProgram/LoyaltyProgramApprovalPage"));
const ExchangeUserConfirmPage = lazy(() => import("../pages/exchanges/ExchangeUserConfirmPage"));
const FiatPayoutUserConfirmPage = lazy(() => import("../pages/fiatPayout/FiatPayoutUserConfirmPage"));
const BenefitsPage = lazy(() => import("../pages/benefitsPage/BenefitsPage"));
const BenefitsDetailPage = lazy(() => import("../pages/benefitsPage/BenefitsDetailPage"));
const PluginsPage = lazy(() => import("../pages/plugins/PluginsPage"));
const JoomlaPage = lazy(() => import("../pages/plugins/JoomlaPage"));
const MagentoPage = lazy(() => import("../pages/plugins/MagentoPage"));
const OpenCartPage = lazy(() => import("../pages/plugins/OpenCartPage"));
const WooCommercePage = lazy(() => import("../pages/plugins/WooCommercePage"));
const CoinListPage = lazy(() => import("../pages/coinList/CoinListPage"));
const CoinListDetailPage = lazy(() => import("../pages/coinList/CoinListDetailPage"));
const InformationPage = lazy(() => import("../pages/information/InformationPage"));
const FaqPage = lazy(() => import("../pages/information/FaqPage"));
const MailingSettingsPage = lazy(() => import("../pages/mailingSettings/MailingSettingsPage"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`;
});

const routes = [
  {
    title: "Home page",
    path: `/:locale(${locale})?`,
    exact: true,
    component: HomePage
  },
  {
    title: "Login page",
    path: `/:locale(${locale})?/login`,
    exact: false,
    component: LoginPage
  },
  {
    title: "About Us",
    path: `/:locale(${locale})?/about-us`,
    exact: false,
    component: AboutUsPage
  },
  {
    title: "Contacts",
    path: `/:locale(${locale})?/contacts`,
    exact: false,
    component: ContactsPage
  },
  {
    title: "Registration page",
    path: `/:locale(${locale})?/registration`,
    exact: false,
    component: RegistrationPage
  },
  {
    title: "Example page",
    path: `/:locale(${locale})?/example-components`,
    exact: false,
    component: ExamplePage
  },
  {
    title: "Email Confirm",
    path: `/:locale(${locale})?/email-confirm/:token?`,
    component: EmailConfirm
  },
  {
    title: "Forgot Password",
    path: `/:locale(${locale})?/forgot-password`,
    component: ForgotPassword
  },
  {
    title: "Change Password",
    path: `/:locale(${locale})?/change-password/:token?`,
    component: ChangePassword
  },
  {
    title: "News Client",
    path: `/:locale(${locale})?/blog`,
    exact: true,
    component: ClientNewsPage
  },
  {
    title: "News Client Details",
    path: `/:locale(${locale})?/blog/:id`,
    exact: true,
    component: ClientNewsDetails
  },
  {
    title: "Payment",
    path: `/:locale(${locale})?/payment/:id`,
    exact: true,
    component: PaymentPage
  },
  {
    title: "Services",
    path: `/:locale(${locale})?/services`,
    exact: true,
    component: ServicesPage
  },
  {
    title: "Service details",
    path: `/:locale(${locale})?/services/:id`,
    exact: true,
    component: ServiceDetailsPage
  },
  {
    title: "Services industries",
    path: `/:locale(${locale})?/services-industries`,
    exact: true,
    component: ServicesIndustriesPage
  },
  {
    title: "Services industries",
    path: `/:locale(${locale})?/services-industries/:id`,
    exact: true,
    component: ServicesIndustriesDetailPage
  },
  {
    title: "Coin list",
    path: `/:locale(${locale})?/coin-list`,
    exact: true,
    component: CoinListPage
  },
  {
    title: "Coin list details",
    path: `/:locale(${locale})?/coin-list/:id`,
    exact: true,
    component: CoinListDetailPage
  },
  {
    title: "LoyaltyProgramApprovalPage",
    path: `/:locale(${locale})?/approve-referral-requisition/:token?`,
    component: LoyaltyProgramApprovalPage
  },
  {
    title: "ExchangeApprovalPage",
    path: `/:locale(${locale})?/user-confirm-exchange/:token?`,
    component: ExchangeUserConfirmPage
  },
  {
    title: "FiatPayoutApprovalPage",
    path: `/:locale(${locale})?/user-confirm-fiat-payout/:token?`,
    component: FiatPayoutUserConfirmPage
  },
  {
    title: "Prices",
    path: `/:locale(${locale})?/prices`,
    exact: true,
    component: PricePage
  },
  {
    title: "Information",
    path: `/:locale(${locale})?/information/:id`,
    exact: true,
    component: InformationPage
  },
  {
    title: "FAQ",
    path: `/:locale(${locale})?/information/faq`,
    exact: true,
    component: FaqPage
  },
  {
    title: "Plugins",
    path: `/:locale(${locale})?/plugins`,
    exact: true,
    component: PluginsPage
  },
  {
    title: "Joomla",
    path: `/:locale(${locale})?/plugins/joomla`,
    exact: true,
    component: JoomlaPage
  },
  {
    title: "Magento",
    path: `/:locale(${locale})?/plugins/magento`,
    exact: true,
    component: MagentoPage
  },
  {
    title: "OpenCart",
    path: `/:locale(${locale})?/plugins/opencart`,
    exact: true,
    component: OpenCartPage
  },
  {
    title: "WooCommerce",
    path: `/:locale(${locale})?/plugins/woocommerce`,
    exact: true,
    component: WooCommercePage
  },
  {
    title: "Benefits",
    path: `/:locale(${locale})?/benefits`,
    exact: true,
    component: BenefitsPage
  },
  {
    title: "Benefits",
    path: `/:locale(${locale})?/benefits/:id`,
    exact: true,
    component: BenefitsDetailPage
  },
  {
    title: "Mailing Settings",
    path: `/:locale(${locale})?/unsubscribe/:token?`,
    component: MailingSettingsPage
  },
  {
    title: "404",
    path: `/:locale(${locale})?/404`,
    exact: true,
    component: PageNotFound
  }
];

export default routes;