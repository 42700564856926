const defaultLanguage = "en";

const browserLanguages = {
  "uk": "ua",
  "en": "en",
  "ru-RU": "ru",
  "en-US": "en",
  "uk-UA": "ua"
};

const examinationSupporterLangWithOutENInToPathName = ["ru", "ua"];

const supportedLanguages = {
  "en": {
    "url": "",
    "routePrefix": "",
    "regex": "\\/$|\\b",
    "deskription": "English"
  },
  "ru": {
    "url": "/ru",
    "routePrefix": "ru",
    "regex": "\\/ru\\b",
    "deskription": "Русский"
  },
  "ua": {
    "url": "/ua",
    "routePrefix": "ua",
    "regex": "\\/ua\\b",
    "deskription": "Українська"
  }
};

const helmetHtmlByLang = {
  "ru": "ru-RU",
  "ua": "uk-UA",
  "en": "en-US"
};

const standardErrorsWay = "error?.response?.data?.data?.errors";
const standardErrorsWayCode = "error.response.status";

const examinationMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

const feedbackStatusConst = {
  NOT_VIEWED: "NOT_VIEWED",
  VIEWED: "VIEWED",
  CLOSED: "CLOSED",
  RESOLVED: "RESOLVED"
};

const projectVerificationConst = {
  AWAITING: "AWAITING",
  VERIFIED: "VERIFIED",
  NOT_VERIFIED: "NOT_VERIFIED"
};

const feedbackCategoryConst = {
  CARD_VERIFICATION: "CARD_VERIFICATION",
  MONEY_BACK: "REFUNDS",
  GENERAL_ISSUES: "GENERAL_ISSUES",
  COOPERATION_OFFER: "COOPERATION_OFFER"
};

const projectStatus = {
  WORKING: "Working",
  VERIFICATION: "Verification",
  VERIFICATION_FAILED: "Verification failed",
  DISABLED: "Disabled",
  VERIFIED: "Verified",
  NOT_VERIFIED: "Not verified",
  DELETED: "Deleted",
  NOT_DELETED: "Not deleted",
  NEW: "new"
};

const transactionStatus = {
  FAIL: "FAIL",
  INIT: "INIT",
  NEW: "NEW",
  PARTIALLY_PROCESSED: "PARTIALLY_PROCESSED",
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  CANCELED: "CANCELED",
  REFUND: "REFUND",
  FINISHED: "FINISHED",
  FROZEN_DUE_AML: "FROZEN_DUE_AML"
};

const invoiceStatus = {
  WORKING: "WORKING",
  DISABLED: "DISABLED"
};

const transactionStatusName = {
  FROZEN_DUE_AML: "FROZEN_DUE_AML"
};

const batchesStatus = {
  PARTIALLY_PROCESSED: "PARTIALLY PROCESSED"
};

const feedback = {
  INITIAL_STATE: { firstname: "", lastname: "", email: "", message: "" }
};

const mailingStatusesConst = {
  SEND_ALL_USERS: "SEND_ALL_USERS",
  SEND_NEW_USERS: "SEND_NEW_USERS"
};

const languages = {
  RU: "ru",
  EN: "en"
};

const PAYMENT_METHODS = "paymentMethods";

const directions = {
  PAYMENT: "payment",
  PAYOUT: "payout",
  IN_EXCHANGE: "inExchange",
  OUT_EXCHANGE: "outExchange"
};

const refilingDirections = {
  PAYMENT: "payment",
  PAYOUT: "payout"
};

const browsers = {
  FIREFOX: "Firefox"
};

// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409,
  HTTP_NOT_FOUND: 404
};

const roles = {
  ADMIN: "ROLE_ADMIN",
  CLIENT: "ROLE_CLIENT",
  SYSTEM: "ROLE_SYSTEM",
  SEO: "ROLE_SEO",
  SUPPORT: "ROLE_SUPPORT"
};

const arrayOperation = {
  FIRST: 0,
  SECOND: 1
};

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+((\\.[a-z-])*(\\.[a-z]{2,}){1,5})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  NAME: "^[a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]{1,30}$",
  REGEX_NUMBER: /^\d+$/,
  NUMBERS: /\D+/g,
  FLOAT_NUMBERS_WITH_DOT: /[^0-9.]/g,
  FLOAT: "^(?:\\d+(?:\\.\\d*)?|\\.\\d+)$",
  FLOAT_PERCENT: "^(?:\\d+(?:\\.\\d{1,8})?|\\.\\d{1,8})$",
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~])[a-zA-Z\d!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,50}$/,
  IP_V4_ADDRESSES: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  IP_V6_ADDRESSES: /^(?:(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(?!(?:.*[0-9a-fA-F]:){8,})(?:(?:[0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4}?(?::[0-9a-fA-F]{1,4}){0,1}?)?::(?:[0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4}?(?::[0-9a-fA-F]{1,4}){0,1}?)$/,
  DECIMAL_AMOUNT: /^(\d{0,10})(\.\d{0,8})?$/g,
  CYRILLIC_SYMBOLS: /[а-яА-ЯЁёҐґІіЇїЄє]/u,
  TWO_FA: "^\\d{6}$",
  CAPITALIZED_LETTERS: /([A-Z])/g
};

const zeroValue = 0;
const maxPercent = 100;

const emailLength = 50;
const nameLength = 30;

const minLength = 10;

const percentPrecision = 2;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const mercureTimeOut = 55000;

const mercureWaitTimeOut = 1000;

const mercureErrorTimeOut = 1000;
const mercureMultiplyErrorTimeOut = 2;

const timeConsts = {
  DAY: 86400000,
  SEVEN_DAYS: 604800,
  NORMALIZER: 1000
};

const batchesStableAttributesCount = 2;

const currencyType = {
  BALANCE_REFILING: "BALANCE_REFILING",
  CARD: "CARD",
  CRYPTO: "CRYPTO",
  CURRENCY: "CURRENCY",
  MONOB: "MONOB",
  OSDB: "OSDB",
  P24: "P24",
  PMBB: "PMBB",
  SEPA: "SEPA"
};

const logs_levels = {
  INFO: "Info",
  ERROR: "Error",
  WARNING: "Warning"
};

const verification = {
  "1": "Verified",
  "0": "Not verified",
  "null": "Didn't start verification"
};

const paymentMethodIsActivatedConst = {
  ACTIVATED: "1",
  NOT_ACTIVATED: "0"
};

const paymentMethodStatus = {
  ACTIVATED: "ACTIVE",
  NOT_ACTIVATED: "INACTIVE"
};

const createBatchesFieldNames = {
  name: "Name",
  project: "Project",
  currency: "Currency",
  paymentSystem: "paymentSystem",
  file: "File",
  code: "GA-code",
  amountInUSDT: "amountInUSDT",
  isStabling: "isStabling",
  inBodyFee: "inBodyFee"
};

const paymentMethodTypes = {
  PAYMENT: "Payment",
  PAYOUT: "Payout"
};

const notificationTypes = {
  INFO: "info",
  CRITICAL: "critical",
  NOTICE: "notice",
  FROZEN_DUE_AML: "frozen_due_aml",
  ERROR: "error"
};

const attributesNames = {
  AMOUNT: "amount",
  WALLET: "wallet",
  TAG: "tag",
  STABLING_CURRENCY: "stablingCurrency"
};
const author = {
  MANAGER: "admin",
  CLIENT: "client"
};
const filterActiveStatus = {
  isActive: "Active",
  isBanned: "Banned"
};

const projectFeesConsts = {
  BALANCE_STABLING: "balanceStabling",
  FIXED_COURSE: "fixedCourse",
  BODY_FEE: "bodyFee",
  PERCENT_PRECISION: 3,
  DIRECTION_PAYMENT: "payment",
  DIRECTION_PAYOUT: "payout"
};

const projectSettings = {
  TRUSTED_IP_ADDRESSES: "trustedIpAddress",
  FRAME_DEFAULT_LANGUAGE: "frameDefaultLanguage",
  CLIENT_EMAIL_LANGUAGE: "clientEmailLanguage",
  STABLING_CURRENCY: "stablingCurrency"
};

const projectConst = {
  MAX_TRUSTED_IP_COUNT: 5
};

const verificationSchemas = {
  DOCUMENT: "KYC",
  BUSINESS: "KYB"
};

const projectPaymentMethodsConsts = {
  EXCHANGE_PERCENT_NAME: "exchangePercent",
  EXCHANGE_ACTIVE_NAME: "active",
  BODY_FEE_PERCENT_NAME: "bodyFeePercent",
  BODY_FEE_CONSTANT_NAME: "bodyFeeConstant"
};

const footerExceptions = {
  LOGIN: "/login",
  REGISTRATION: "/registration"
};

const acceptableFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/webp"
];

const percentName = "percent";

const max2FaLength = 6;

const staticPagesTypes = {
  PAGE: "page",
  SECTION: "section"
};

const staticPagesFormParams = {
  PRIORITY: "priority",
  MAX_UPLOAD_IMAGES_COUNT: 3
};

const uploadImageErrorKeys = {
  MAX_NUMBER: "maxNumber",
  WRONG_TYPE_FILE: "acceptType",
  FILE_IS_TO_BIG: "maxFileSize"
};

const staticPagesNoContent = "empty";

const imageTypes = {
  PREVIEW: "preview",
  DETAIL: "detail",
  GALLERY: "gallery"
};

const maxSymbolsLength = 300;
const maxSymbolsDescriptionLength = 255;

const registrationTypes = {
  DEFAULT: "default",
  TRAFFIC: "traffic",
  REFERRAL: "referral"
};

const referralLevelsTypes = {
  DEFAULT_TYPE: "default",
  CUSTOM_TYPE: "custom"
};

const payoutRequisitionListTypes = {
  INFO: "info",
  HISTORY: "history",
  ADMIN: "admin"
};

const currencyAssets = {
  USDT: "USDT",
  USD: "USD",
  EUR: "EUR"
};

const balancesExcludedCurrencyAssets = ["EUR"];

const booleanConst = {
  TRUE: "1",
  FALSE: "0"
};

const profitsBalancesTypes = {
  REFERRAL_PROFIT: "referralProfit",
  REFERRAL_BALANCE: "referralBalance"
};

const buttonStatuses = {
  inactive: "inactive",
  active: "active",
  loading: "loading"
};

const invoiceRegex = {
  amount: /^\d*(?:\.\d{0,8})?$/,
  clientOrderId: /^[A-Za-z\d-]*$/
};

const payoutRequisitionsTypes = {
  TYPE_DEFAULT: "default",
  TYPE_PROJECT: "project"
};

const referralLevelActions = [
  {
    title: "For all clients",
    action: "forAllClients"
  },
  {
    title: "For new clients",
    action: "forNewClients"
  },
  {
    title: "For clients with vip percent",
    action: "forVipClients"
  },
  {
    title: "For all excluding vip",
    action: "forAllExcludingVip"
  }
];

const invoiceNames = {
  PAY_BY_LINK: "PayByLinkInvoice",
  INVOICE: "StandardInvoice"
};

const standardInvoicesConsts = {
  MAX_EXPIRE_TIME_INTERVAL_HOURS: 72 * 60 * 60
};

const dateFormatsForPaymentStatisticsChart = {
  DATE_MONTH_DAY_YEAR: "MMM DD YYYY",
  DATE_HOURS_MINUTES: "HH:mm"
};

const buttonsForPaymentStatisticsChart = {
  MAIN_BUTTON: "main",
  DAY_BUTTON: "day-button",
  WEEK_BUTTON: "week-button",
  MONTH_BUTTON: "month-button",
  THREE_MONTH_BUTTON: "three-month-button"
};

const referralLevelTypes = {
  DEFAULT: "default",
  CUSTOM: "custom"
};
const payoutRequisitionStatus = {
  NEW: "NEW",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  APPROVED_BY_CLIENT: "APPROVED_BY_CLIENT"
};

const rangeNames = {
  TODAY_RANGE: "Today",
  WEEK_RANGE: "Week",
  MONTH_RANGE: "Month",
  THREE_MONTH_RANGE: "3 Months"
};

const mailingSettings = {
  MAILING_LANGUAGE: "mailingLanguage",
  SUCCESSFUL_INVOICE_PAYMENTS: "successfulInvoicePayments",
  SUCCESSFUL_PAY_BY_LINK: "successfulPayByLink",
  SUCCESSFUL_POS: "successfulPOSPayment",
  SUCCESSFUL_PAY_BUTTON: "successfulPayButton"
};

const paybuttonVariantsPath = "/assets/images/pay-buttons/";

const posTerminalThemes = [
  {
    id: "dark",
    title: "posLink.theme.dark"
  },
  {
    id: "light",
    title: "posLink.theme.light"
  }
];

const fileLoadParams = {
  MAX_AMOUNT: 10,
  MAX_SIZE: 5242880,
  ERROR_SIZE: 0,
  ACCEPTED_TYPES: ["jpg", "png", "jpeg"]
};

const sortDirection = {
  ASC: "asc",
  DESC: "desc"
};

const waitTimer = {
  ZERO: 0,
  MAX: 60,
  INTERVAL: 1,
  INTERVAL_MS: 1000
};

const authCodeLength = 4;

const verificationAttributeTypes = {
  INPUT: "input",
  FILE: "file"
};

const attributeFieldSettingsNames = {
  REGEX: "regex",
  MEDIA_OBJECT_TYPE: "mediaObjectType"
};

const fileValidations = {
  SIZE: 5 * 1024 * 1024,
  PDF_FORMAT: "application/pdf"
};

const attributeVerificationStatus = {
  NEW: "NEW",
  CANCELED: "CANCELED",
  VERIFIED: "VERIFIED"
};

const exchangePairFields = {
  percent: "percent"
};

const OneMinuteTimerConst = 1000 * 60;

const OneHourTimerConst = 1000 * 60 * 60;

const decimalFixed = 16;
const decimalFixedMid = 10;
const decimalFixedShort = 8;

const messageMaxLength = 3600;

const pluginsList = {
  OPENCART: "opencart",
  WOOCOMMERCE: "woocommerce",
  JOOMLA: "joomla",
  MAGENTO: "magento"
};

const invoicesFields = {
  PROJECT: "project"
};

const fiatExchangeCurrencies = [
  { "name": "Euro", "asset": "EUR" }
];

const stablingCurrencies = [
  { "name": "Tether", "asset": "USDT" },
  { "name": "Euro", "asset": "EUR" }
];

const exchangeStatus = {
  ADMIN_CONFIRMATION: "ADMIN_CONFIRMATION",
  USER_CONFIRMATION: "USER_CONFIRMATION",
  CANCELED: "CANCELED",
  PENDING: "PENDING"
};

const emptyFieldArrayConst = ["", undefined, NaN, null];

const errorsFieldsNames = {
  WILL_GET: "willGet"
};

const loginFormInputs = {
  EMAIL: "email",
  PASSWORD: "password"
};

const frontendSettings = {
  hideZeroBalances: "hideZeroBalances",
  hideZeroPaymentMethods: "hideZeroPaymentMethods"
};

const currenciesPaymentActivity = {
  USD: "USD",
  EUR: "EUR",
  USDT: "USDT",
  BTC: "BTC",
  ETH: "ETH"
};

const buttonsForReportsFilter = {
  ONE_DAY_BUTTON: "oneDay",
  SEVEN_DAYS_BUTTON: "sevenDays",
  THIRTY_DAYS_BUTTON: "thirtyDays",
  NINETY_DAYS_BUTTON: "ninetyDays",
  ONE_HUNDRED_EIGHTY_DAYS: "oneHundredEightyDays",
  ONE_YEAR_BUTTON: "oneYear",
  ALL_TIME_BUTTON: "allTime",
};

const userTypes = {
  PERSONAL: "personal",
  BUSINESS: "business"
};

const filterTransactionsStatuses = {
  FINISHED: "FINISHED",
  PENDING: "PENDING",
  FAIL: "FAIL",
  FROZEN_DUE_AML: "FROZEN_DUE_AML"
};

const payByLinkTypes = {
  PAY_BY_LINK: 0,
  PAY_BUTTON: 1
};

const tokenParam = {
  IS_VERIFIED: "isVerified"
}

const adminStatusesUpdateExclusions = [transactionStatus.FAIL];

export {
  filterActiveStatus,
  feedbackStatusConst,
  examinationMobileDevice,
  feedbackCategoryConst,
  mailingStatusesConst,
  directions,
  languages,
  responseStatus,
  roles,
  author,
  regex,
  arrayOperation,
  mercureTopicOrigin,
  mercureUrl,
  mercureTimeOut,
  mercureErrorTimeOut,
  minLength,
  timeConsts,
  feedback,
  logs_levels,
  currencyType,
  projectStatus,
  supportedLanguages,
  helmetHtmlByLang,
  defaultLanguage,
  transactionStatus,
  invoiceStatus,
  paymentMethodIsActivatedConst,
  paymentMethodStatus,
  createBatchesFieldNames,
  paymentMethodTypes,
  batchesStatus,
  acceptableFileTypes,
  attributesNames,
  emailLength,
  nameLength,
  projectFeesConsts,
  browsers,
  zeroValue,
  maxPercent,
  percentName,
  batchesStableAttributesCount,
  percentPrecision,
  verification,
  projectSettings,
  projectConst,
  verificationSchemas,
  projectPaymentMethodsConsts,
  max2FaLength,
  standardErrorsWay,
  standardErrorsWayCode,
  staticPagesTypes,
  staticPagesNoContent,
  transactionStatusName,
  imageTypes,
  footerExceptions,
  staticPagesFormParams,
  maxSymbolsLength,
  uploadImageErrorKeys,
  registrationTypes,
  referralLevelsTypes,
  payoutRequisitionListTypes,
  currencyAssets,
  booleanConst,
  profitsBalancesTypes,
  invoiceRegex,
  buttonStatuses,
  payoutRequisitionsTypes,
  referralLevelActions,
  dateFormatsForPaymentStatisticsChart,
  buttonsForPaymentStatisticsChart,
  invoiceNames,
  rangeNames,
  referralLevelTypes,
  payoutRequisitionStatus,
  standardInvoicesConsts,
  browserLanguages,
  mailingSettings,
  mercureWaitTimeOut,
  mercureMultiplyErrorTimeOut,
  paybuttonVariantsPath,
  maxSymbolsDescriptionLength,
  posTerminalThemes,
  fileLoadParams,
  sortDirection,
  exchangePairFields,
  waitTimer,
  authCodeLength,
  decimalFixed,
  verificationAttributeTypes,
  fileValidations,
  attributeFieldSettingsNames,
  attributeVerificationStatus,
  projectVerificationConst,
  messageMaxLength,
  pluginsList,
  invoicesFields,
  OneMinuteTimerConst,
  OneHourTimerConst,
  fiatExchangeCurrencies,
  exchangeStatus,
  emptyFieldArrayConst,
  errorsFieldsNames,
  loginFormInputs,
  decimalFixedShort,
  decimalFixedMid,
  notificationTypes,
  examinationSupporterLangWithOutENInToPathName,
  frontendSettings,
  stablingCurrencies,
  balancesExcludedCurrencyAssets,
  PAYMENT_METHODS,
  currenciesPaymentActivity,
  buttonsForReportsFilter,
  userTypes,
  filterTransactionsStatuses,
  payByLinkTypes,
  refilingDirections,
  tokenParam,
  adminStatusesUpdateExclusions
};
